import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDetailsPropertyUnAuthAction } from '../../store/actions/propertyAction/detailsPropertyAction';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Spin } from 'antd';
import PageNotFoundProperty from '../../components/properties/properties/sub-component/details-property/PageNotFoundProperty';
import { ReactComponent as PhotoIcon } from "../../assets/imgs/icons/no-photo.svg";

import "./style.scss"
import CarouselWithDots from '../../components/carousel/CarouselWithDots';
import {
  ArrowRightOutlined,
  WhatsAppOutlined,
  PhoneOutlined,
} from "@ant-design/icons";

const DetailsUnAuth = () => {
  const dispatch = useDispatch();
  const lists = useSelector((state) => state.lists);


  const {
    data: detailsProperty,
    loadingPage,
    pageNotFound,
  } = useSelector((state) => state.property.detailsPropertyUnAuth);

  const { id, tenantCode } = useParams();



  useEffect(() => {
    dispatch(getDetailsPropertyUnAuthAction(id, tenantCode));
  }, [id, dispatch, tenantCode]);

  var position = {
    lat: detailsProperty.proprtyDetailesVM?.lat,
    lng: detailsProperty.proprtyDetailesVM?.lang,
  };
  const loc = `https://maps.google.com/maps?q=${position.lat},${position.lng}&hl=en&z=14&amp;&output=embed`;


  const listingDetailsInformation = [
    {
      title: "Title:",
      data: detailsProperty.proprtyDetailesVM?.title || "-",
    },
    {
      title: "Code:",
      data: detailsProperty.proprtyDetailesVM?.id || "-",
    },
    {
      title: "Section:",
      data: detailsProperty.proprtyDetailesVM?.section || "-",
    },
    {
      title: "Property Type:",
      data: detailsProperty.proprtyDetailesVM?.proprtyTypename || "-",
    },
    {
      title: "Floor:",
      data:
        lists?.Floor?.data.find(
          (floor) => floor?.id === detailsProperty.proprtyDetailesVM?.floorId
        )?.name || "-",
    },

    {
      title: "Price:",
      data: detailsProperty.proprtyDetailesVM?.price || "-",
    },

    {
      title: "Rooms:",
      data:
        lists?.Rooms?.data.find(
          (room) => room?.id === detailsProperty.proprtyDetailesVM?.roomsId
        )?.name || "-",
    },

    {
      title: "Bath rooms:",
      data:
        lists?.Bathrooms?.data.find(
          (br) => br?.id === detailsProperty.proprtyDetailesVM?.bathroomsId
        )?.name || "-",
    },

    {
      title: "Finishing Type:",

      data:
        lists["Finishing Type"]?.data.find(
          (ft) => ft?.id === detailsProperty.proprtyDetailesVM?.finishingTypeId
        )?.name || "-",
    },

    {
      title: "Space:",
      data: detailsProperty.proprtyDetailesVM?.space || "-",
    },

    {
      title: "Payment Method:",
      data: detailsProperty.proprtyDetailesVM?.paymentMethod || "-",
    },

    {
      title: "Sales:",
      data: detailsProperty.proprtyDetailesVM?.username || "-",
    },

    {
      title: "Address:",
      data: ` ${detailsProperty.proprtyDetailesVM?.cityname} - ${detailsProperty.proprtyDetailesVM?.areaname} - ${detailsProperty.proprtyDetailesVM?.districtname} - ${detailsProperty.proprtyDetailesVM?.regionname}`,
    },
    {
      title: "Sales Phone:",
      data: detailsProperty.proprtyDetailesVM?.usernphone || "-",
      showPhoneIcon: true,
    }
  ];

  if(pageNotFound) {
    return (
      <div className="details-property-unauth">
        <PageNotFoundProperty id={id} />
      </div>
    );
  }

  return (
    <Spin spinning={loadingPage}>
      <div className="details-property-unauth">
        <div className="details-container">
          <div className="title">
            <h3>Details property</h3>
          </div>

          <div className="body">
            <div className="section-action">
              {detailsProperty?.images?.length === 0 ||
              detailsProperty?.images?.length === undefined ? (
                <div className="no-images">
                  <PhotoIcon />
                  <p>No images available </p>
                </div>
              ) : (
                <CarouselWithDots
                  srcImages={detailsProperty?.images || []}
                  dots={true}
                />
              )}
            </div>

            <div className="section-listing">
              <div className="title-section">Listing Details</div>
              <div className="data-section">
                {listingDetailsInformation.map(({ title, data, showPhoneIcon= false }, index) => {
                  return (
                    <div className={`grid-item`} key={index}>
                      <div className="title-info dot-title">
                        <span className="dot"></span>
                        {title}
                      </div>
                      <div className="data-info">
                        <span>{data}</span>
                        {showPhoneIcon && (
                          <>
                            <div className="phone-icons">
                              <a
                                href={`https://wa.me/+${data}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <WhatsAppOutlined />
                              </a>
                              <a
                                href={`tel:+${data}`}
                                rel="noreferrer"
                              >
                                <PhoneOutlined />
                              </a>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="section-details-property">
              {detailsProperty.proprtyDetailesVM?.detailsPropert && (
                <>
                  <div className="title-section">Details Property</div>
                  <p>{detailsProperty.proprtyDetailesVM?.detailsPropert || ""}</p>
                </>
              )}
            </div>

            <div className="section-map">
              <div className="title-section">Map</div>
              <div className="map">
                <iframe
                  title="map"
                  id="myiframe"
                  src={loc}
                  frameBorder="0"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
}

export default DetailsUnAuth