import React, { useEffect } from "react";
import "./App.scss";
import { useDispatch } from "react-redux";
// import { BrowserRouter as Router } from "react-router-dom";
import { HashRouter as Router } from "react-router-dom";
import { instance } from "../../network/axiosConfig";

import Routes from "../../app-routes/Routes";

import { setUnautherizedMessageAction } from "../../store/actions/loginAction";
import { getAllPropertyAction } from "../../store/actions/listsAction/listAction";

function App() {
  const dispatch = useDispatch();

  // if error Unautherized happen  in respose
  useEffect(() => {
    instance.interceptors.response.use(
      function (response) {
        // Do something with response data
        return response;
      },
      function (error) {
        // Do something with response error
        if (error.response?.status === 401 || error.response?.status === 403)
          dispatch(setUnautherizedMessageAction(true));

        return Promise.reject(error);
      }
    );
  });

  return (
    <>
      <Router hashType="slash">
        <div className="App">
          <Routes />
        </div>
      </Router>
    </>
  );
}

export default App;
