import * as TYPES from "../types";
import { instance } from "../../network/axiosConfig";
import { setRoutesAction } from "./routesAllowedAction";
//import * as XLSX from "xlsx";

//                                     Login                                              //

export const setLoginAction = (informationLogin, reset) => async (dispatch) => {
  try {
    dispatch({
      type: TYPES.LOADING_LOGIN,
      payload: true,
    });

    const formData = new FormData();
    formData.append("PhoneNumber", informationLogin.phoneNumber);
    formData.append("Password", informationLogin.password);


    const res = await instance.post("/Account/login", formData, {
      headers: {
        "tenant-code": localStorage.getItem("companyName")
      },
    });
    // res = {
    //   status: 200,
    //   data: { token: 444 },
    // };

    if (res.status === 200) {
      //console.log(res.data.model);
      dispatch(setRoutesAction(res.data.model.roles));
      localStorage.setItem("Authorization", `Bearer ${res.data.model.token}`);
      localStorage.setItem(
        "informationUserTest",
        JSON.stringify(res.data.model)
      );
      const { email, phoneNumber, isPaid, userImage, userName, roles } =
        res.data.model;

      localStorage.setItem(
        "informationUser",
        JSON.stringify({
          email,
          phoneNumber,
          isPaid,
          userImage,
          userName,
          password: "********",
          groupId: roles.groupId,
        })
      );

      dispatch({
        type: TYPES.INFORMATIONS_USER,
        payload: {
          email,
          phoneNumber,
          isPaid,
          userImage,
          userName,
          password: "********",
          groupId: roles.groupId,
        },
      });

      dispatch({
        type: TYPES.MESSAGE_LOGIN,
        payload: "",
      });

      dispatch({
        type: TYPES.LOGIN,
        payload: true,
      });
    }
  } catch (error) {
    dispatch({
      type: TYPES.LOGIN,
      payload: false,
    });
    dispatch({
      type: TYPES.MESSAGE_LOGIN,
      payload: error.response?.data?.message || "Try again",
    });
  } finally {
    dispatch({
      type: TYPES.LOADING_LOGIN,
      payload: false,
    });
    // reset form
    reset();
  }
};

export const setLogOutAction = (history) => async (dispatch) => {
  try {
    localStorage.clear();

    dispatch({
      type: TYPES.LOGIN,
      payload: false,
    });

    dispatch(setUnautherizedMessageAction(false));
    
    history.push("/login");
  } catch (error) {
    console.log(error);
  }
};

export const setUnautherizedMessageAction = (visible) => async (dispatch) => {
  dispatch({
    type: TYPES.MESSAGE_UNAUTHORIZED_LOGIN,
    payload: {
      visible,
      message: visible ? "CRM is open in another device, please login in " : "",
    },
  });
};

export const editProfileAction =
  ({ UserName, Email, PhoneNumber, Password }, groupId, image) =>
  async (dispatch) => {
    try {
      dispatch({
        type: TYPES.LOADING_ALL_PAGE,
        payload: true,
      });

      // form to send to server
      const formData = new FormData();
      formData.append("UserName", UserName);
      formData.append("Email", Email);
      formData.append("PhoneNumber", PhoneNumber);
      formData.append("UserType", 1);
      formData.append("GroupId", groupId);

      Password !== "********" && formData.append("Password", Password);

      image === "delete"
        ? formData.append("UserImage", "null")
        : image && formData.append("UserImage", image);

      const res = await instance.post("Account/editProfile", formData, {
        headers: {
          "tenant-code": localStorage.getItem("companyName"),
        },
      });
      if (res.status === 200) {
        //console.log(res);

        const {
          email,
          phoneNumber,
          isPaid,
          userImageView: userImage,
          userName,
          groupId,
        } = res.data.model;

        dispatch({
          type: TYPES.INFORMATIONS_USER,
          payload: {
            email,
            phoneNumber,
            isPaid,
            userImage,
            userName,
            password: "********",
            groupId,
          },
        });

        localStorage.setItem(
          "informationUser",
          JSON.stringify({
            email,
            phoneNumber,
            isPaid,
            userImage,
            userName,
            password: "********",
            groupId,
          })
        );
      }
    } catch (error) {
      console.log(error?.response || error);
    } finally {
      dispatch({
        type: TYPES.LOADING_ALL_PAGE,
        payload: false,
      });
    }
  };
