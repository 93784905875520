import * as TYPES from "./../../types";


const initState = {
  data: [],
  loading: false,
  total: 0
}



const requestsReducer = (state = initState, action) => {
  switch(action.type) {
    case TYPES.GET_ALL_REQUESTS:
      console.log("action.payload.data :", action.payload.data)

      return {
        ...state,
        data: action.payload.data,
        loading: action.payload.loading,
        total: action.payload.total
      }

    default:
      return state;
  }
};

export default requestsReducer;
