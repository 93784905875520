/* eslint-disable import/no-anonymous-default-export */
import * as TYPES from "../types";

export default (
  state = {
    routesMainPagesAllowed:
      JSON.parse(localStorage.getItem("finalSetPages")) || [],
    //
    homeMainPage: JSON.parse(localStorage.getItem("homeMainPage")) || {},
    //
    routesPrivilegeAllowed:
      JSON.parse(localStorage.getItem("privilegePages")) || [],

    //
    subRoutesPrivilegeAllowed:
      JSON.parse(localStorage.getItem("subPrivilegePages")) || [],
  },

  action
) => {
  switch (action.type) {
    case TYPES.ROUTES_MAIN_PAGES_ALLOWED:
      return {
        ...state,
        routesMainPagesAllowed: action.payload,
      };

    case TYPES.ROUTES_PRIVILEGE_ALLOWED:
      return {
        ...state,
        routesPrivilegeAllowed: action.payload,
      };

    case TYPES.SUB_ROUTES_PRIVILEGE_ALLOWED:
      return {
        ...state,
        subRoutesPrivilegeAllowed: action.payload,
      };

    case TYPES.HOME_MAIN_PAGE:
      return {
        ...state,
        homeMainPage: action.payload,
      };

    default:
      return state;
  }
};
