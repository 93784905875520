import * as TYPES from "../../types";
import { instance } from "../../../network/axiosConfig";

export const setAddEditListsAction = (data) => async (dispatch) => {
  dispatch({
    type: TYPES.ADD_EDIT_LISTS,
    payload: data,
  });
};

export const getAllPropertyAction = () => async (dispatch) => {
  try {
    dispatch({
      type: TYPES.ALL_PROPERTY_LISTS,
      payload: {
        data: [],
        loading: true,
      },
    });

    const res = await instance.get("properties/AllDetailsProperty");

    if (res.status === 200) {
      dispatch({
        type: TYPES.ALL_PROPERTY_LISTS,
        payload: { data: res.data.model, loading: false },
      });
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: TYPES.ALL_PROPERTY_LISTS,
      payload: { data: [], loading: false },
    });
  }
};

// export const setLoadingPageAddEditListsAction = (data) => async (dispatch) => {
//   dispatch({
//     type: TYPES.LOADING_PAGE_ADD_EDIT_LISTS,
//     payload: data,
//   });
// };
