import React from "react";
import { Link } from "react-router-dom";
import Lottie from "react-lottie";
import Animation404 from "../../../../../assets/lottie/404-page-not-found.json"


const PageNotFoundProperty = ({ id }) => {

  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: Animation404,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  return (
    <div className="Page-not-found-property">
      <div>
        <Lottie options={defaultOptions} width={"100%"} heigh={"100%"} />
      </div>
      <p style={{
        color: "rgb(25 33 52)",
        fontSize: "22px",
        marginTop: "25px"
      }}> The Property with ID = {id} is not Found </p>
      {/* <Link to={-1}>
        <span className="btn">
          Back
        </span>
      </Link> */}
    </div>
  );
};

export default PageNotFoundProperty;
