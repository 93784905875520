import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend"
import LanguageDetector from "i18next-browser-languagedetector"

import translationEN from "./lang/en.json";
import translationAR from "./lang/ar.json";

const resources = {
  en: {
    translation: translationEN
  },
  ar: {
    translation: translationAR
  }
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem("i18nextLng") || "en",
    debug: false,
    interpolation: {
      escapeValue: false,
      formatSeparator:",",
    },
    react: {
      useSuspense: false,
    }
  })

export default i18n 