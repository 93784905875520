import * as TYPES from "../../types";
import { instance } from "../../../network/axiosConfig";
import moment from "moment";
import axios from "axios";

// details property

export const setDetailsPropertyAction = (allData) => async (dispatch) => {
  try {
    dispatch({
      type: TYPES.DETAILS_PROPERTY,
      payload: {
        data: { ...allData },
        loadingPage: false,
      },
    });
  } catch (error) {
    console.log(error);
  }
};

export const getDetailsPropertyAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: TYPES.DETAILS_PROPERTY,
      payload: {
        data: {},
        loadingPage: true,
      },
    });

    const formData = new FormData();
    formData.append("ID", id);

    const res = await instance.post("ProprtiesDetailes/ProprtybyID", formData);
    if (res.status === 200) {
      dispatch({
        type: TYPES.DETAILS_PROPERTY,
        payload: {
          data: res.data?.model[0] || {},
          loadingPage: false,
          pageNotFound: res.data?.model.length === 0 ? true : false,
        },
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const setAddEditRequestAction = (data) => async (dispatch) => {
  dispatch({
    type: TYPES.ADD_EDIT_REQUEST,
    payload: data,
  });
};

export const setRequestToPropertyAction = (comment, id) => async (dispatch) => {
  try {
    dispatch({
      type: TYPES.CHANGE_LOADING_MESSAGE_DIALOG_REQUEST,
      payload: {
        loading: true,
      },
    });

    // form to send to server
    const formData = new FormData();
    formData.append(`coment`, comment);
    formData.append("ProprtyDetailesId", id.ProprtyDetailesId);

    const res = await instance.post("ActionandRquest/AddRequests", formData);

    if (res.status === 200) {
      //console.log(res);
      dispatch(setAddEditRequestAction({}));
    }
  } catch (error) {
    console.log(error?.response || error);

    if (error?.response?.status === 406 || error?.response?.status === 400) {
      dispatch({
        type: TYPES.CHANGE_LOADING_MESSAGE_DIALOG_REQUEST,
        payload: { message: error.response.data.message, loading: false },
      });
    }
  }
};

export const setSoldPropertyAction =
  (data, id, soldState, setSold) => async (dispatch) => {
    try {
      data.active &&
        setSold({
          isModalVisible: true,
          loading: true,
        });

      // form to send to server
      const formData = new FormData();
      data.active &&
        formData.append("Saledate", moment(data.saleDate).format());
      data.active && formData.append("saleAmount", data.saleAmount);
      formData.append("Proprityid", id);
      formData.append("Active", data.active);

      const res = await instance.post(
        "ProprtiesDetailes/SoldProprty",
        formData
      );

      if (res.status === 200) {
        //console.log(res);

        dispatch({
          type: TYPES.CHANGE_CASE_RENT_OR_SOLD,
          payload: "sold",
        });

        data.active &&
          setSold({
            isModalVisible: false,
            loading: false,
          });
      }
    } catch (error) {
      console.log(error?.response || error);

      if (error?.response?.status === 406 || error?.response?.status === 400) {
        data.active &&
          setSold({
            ...soldState,
            isModalVisible: true,
            loading: false,
            message: error.response.data.message,
          });
      }
    }
  };

export const setRentPropertyAction =
  (data, id, soldState, setSold) => async (dispatch) => {
    try {
      // form to send to server
      data.rent &&
        setSold({
          isModalVisible: true,
          loading: true,
          saleOrRent: false,
        });
      const formData = new FormData();
      data.rent && formData.append("rentamount", data.saleAmount);
      data.rent && formData.append("from", moment(data.saleDate[0]).format());
      data.rent && formData.append("to", moment(data.saleDate[1]).format());

      formData.append("Active", data.rent);
      formData.append("ProprtyDetailesid", id);

      const res = await instance.post(
        "ProprtiesDetailes/Rentproprtiy",
        formData
      );

      if (res.status === 200) {
        //console.log(res);

        dispatch({
          type: TYPES.DETAILS_PROPERTY,
          payload: {
            data: res.data?.model[0] || {},
            loadingPage: false,
            pageNotFound: res.data?.model.length === 0 ? true : false,
          },
        });

        // dispatch({
        //   type: TYPES.CHANGE_CASE_RENT_OR_SOLD,
        //   payload: "rent",
        // });

        data.rent &&
          setSold({
            isModalVisible: false,
            loading: false,
            saleOrRent: false,
          });
      }
    } catch (error) {
      console.log(error?.response || error);

      if (error?.response?.status === 406 || error?.response?.status === 400) {
        data.rent &&
          setSold({
            ...soldState,
            isModalVisible: true,
            loading: false,
            saleOrRent: false,
            message: error.response.data.message,
          });
      }
    }
  };


export const getDetailsPropertyUnAuthAction = (id, tenantCode) => async (dispatch) => {
  try {

    dispatch({
      type: TYPES.GET_DETAILS_PROPERTY_UNAUTH,
      payload: {
        data: {},
        loadingPage: true,
      },
    });

    const formData = new FormData();
    formData.append("ID", id);

    // const res = await instance.get(`ProgectAuth/ProprtybyID?ID=${id}`);

    const res = await axios.post(
      `http://crmbackend.aqar-cloud.com/api/ProprtiesDetailes/PropertyByIdForClient`, 
      formData, 
      {
        headers: {
          "tenant-code": tenantCode,
          "content-type": "multipart/form-data"
        }
      }
    )
    // const res = await instance.post(`ProprtiesDetailes/PropertyByIdForClient`, formData);

    if (res.status === 200) {
      // console.log(res);

      dispatch({
        type: TYPES.GET_DETAILS_PROPERTY_UNAUTH,
        payload: {
          data: res.data?.model[0] || {},
          loadingPage: false,
          pageNotFound: res.data?.model.length === 0 ? true : false,
        },
      });

    }

  } catch(err) {
    dispatch({
      type: TYPES.GET_DETAILS_PROPERTY_UNAUTH,
      payload: {
        data: {},
        loadingPage: false,
        pageNotFound: true,
      },
    });
  }
}

export const getMultiPropertyAction = (PropertiesCodes, tenantCode) => async (dispatch) => {
  try {

    dispatch({
      type: TYPES.GET_MULTI_PROPERTY_UNAUTH,
      payload: {
        data: [],
        loadingPage: true,
      },
    });

    const formData = new FormData();
    formData.append("PropertiesCodes", PropertiesCodes);
    formData.append("tenant-code", tenantCode);

    const res = await axios.post(
      `http://crmbackend.aqar-cloud.com/api/ProprtiesDetailes/PropertiesByIdForClient`,
      formData,
      {
        headers: {
          "tenant-code": tenantCode,
          "content-type": "multipart/form-data"
        }
      }
    )

    // const res = await instance.post(`ProprtiesDetailes/PropertiesByIdForClient`, formData);

    if (res.status === 200) {
      // console.log(res);

      dispatch({
        type: TYPES.GET_MULTI_PROPERTY_UNAUTH,
        payload: {
          data: res.data?.model,
          loadingPage: false,
        },
      });

    }

  } catch(err) {
    dispatch({
      type: TYPES.GET_MULTI_PROPERTY_UNAUTH,
      payload: {
        data: [],
        loadingPage: false,
      },
    });
  }
}