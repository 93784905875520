import projectsTypes from "./projects.types";

const initialState = {
  loading: false,
  data: null,
  error: null,
};

const projectsCompaniesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case projectsTypes.FETCH_ALL_COMPANIES_DATA_REQUEST:
      return { ...state, loading: true, data: null, error: null };

    case projectsTypes.FETCH_ALL_COMPANIES_DATA_SUCCESS:
      return { ...state, loading: false, data: payload, error: null };

    case projectsTypes.FETCH_ALL_COMPANIES_DATA_FAILED:
      return { ...state, loading: false, data: null, error: payload };

    default:
      return state;
  }
};

export default projectsCompaniesReducer;
