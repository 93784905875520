/* eslint-disable import/no-anonymous-default-export */
import * as TYPES from "../../types";

export default (
  state = {
    allDasboardCrm: { data: {}, loading: false },
    allActivities: { data: [], loading: false },
    dateType: "year",
    date: ""
  },

  action
) => {
  switch (action.type) {
    case TYPES.ALL_DASHBOARD_CRM:
      return {
        ...state,
        allDasboardCrm: action.payload,
      };

    case TYPES.ALL_ACTIVITIES:
      return {
        ...state,
        allActivities: action.payload,
      };

    case TYPES.GET_DATE_AND_DATE_TYPE:
      return {
        ...state,
        dateType: action.payload.dateType,
        date: action.payload.date,
      }

    default:
      return state;
  }
};
