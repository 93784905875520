/* eslint-disable import/no-anonymous-default-export */
import * as TYPES from "../types";

export default (
  state = {
    selectOneSide: { nameSide: false },
    leadDetails: null,
    loading: true,
    pageNumber: 1,
    pageSize: 10
  },

  action
) => {
  // console.log(state)
  switch (action.type) {
    case TYPES.SIDE_ACTIONS:
      return {
        ...state,
        selectOneSide: action.payload,
      };

    case TYPES.CHANGE_LOADING_MESSAGE_SIDE:
      return {
        ...state,
        selectOneSide: { ...state.selectOneSide, ...action.payload },
      };

    // for add actions
    case TYPES.EDIT_DEFAULT_VALUE_SIDE_ACTIONS:
      return {
        ...state,
        selectOneSide: {
          ...state.selectOneSide,
          defaultValue: {
            ...state.selectOneSide.defaultValue,
            ...action.payload,
          },
        },
      };
    // for add actions
    case TYPES.EDIT_DEFAULT_VALUE_COMMENT_SIDE_ACTIONS:
      return {
        ...state,
        selectOneSide: {
          ...state.selectOneSide,
          defaultValue: {
            ...state.selectOneSide.defaultValue,
            actionsData: [
              ...state.selectOneSide.defaultValue.actionsData,
              action.payload,
            ],
          },
        },
      };

    case TYPES.GET_LEAD_DETAILS:
      return {
        ...state,
        leadDetails: action.payload
      };
    
    case TYPES.LOADING_LEAD_DETAILS:
      return {
        ...state,
        loading: action.payload
      }

    case TYPES.SET_LEAD_PAGE_NUMBER_AND_PAGE_SIZE:
      return {
        ...state,
        pageNumber: action.payload.pageNumber,
        pageSize: action.payload.pageSize
      }

    default:
      return state;
  }
};
