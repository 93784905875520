/* eslint-disable import/no-anonymous-default-export */
import * as TYPES from "../../types";

export default (
  state = {
    addEditCompany: {},
    addEditMemberShips: {},
    addEditValidity: {},
    addEditProject: {},
    editNameOption: {},
    doneOrDeleteDialog: {},
  },

  action
) => {
  switch (action.type) {
    case TYPES.ADD_EDIT_PROJECT:
      return {
        ...state,
        addEditProject: action.payload,
      };

    case TYPES.EDIT_NAME_OPTION:
      return {
        ...state,
        editNameOption: action.payload,
      };

    case TYPES.ADD_EDIT_COMPANY:
      return {
        ...state,
        addEditCompany: action.payload,
      };

    case TYPES.ADD_EDIT_MEMBERSHIPS:
      return {
        ...state,
        addEditMemberShips: action.payload,
      };

    case TYPES.ADD_EDIT_VALIDITY:
      return {
        ...state,
        addEditValidity: action.payload,
      };

    case TYPES.DONE_DELETE_DIALOG:
      return {
        ...state,
        doneOrDeleteDialog: action.payload,
      };

    default:
      return state;
  }
};
