import * as TYPES from "../types";
import routerLinks from "../../app-routes/routerLinks";
// import { detailsPropertiesRoutes } from "../../pages/properties/allRoutesDetails";
//import { instance } from "../../network/axiosConfig";
//import * as XLSX from "xlsx";

//                                     Routes                                              //

const linksRoutesforAllPage = {
  Properties: {
    dashboard: routerLinks.propertiesDashboard,

    properties: routerLinks.propertiesProperties,

    updates: routerLinks.propertiesUpdates,

    // owners: routerLinks.propertiesOwners,
    aqarFinder: routerLinks.aqarFinder,

    lists: routerLinks.propertiesSettingLists,
  },

  CRM: {
    dashboard: routerLinks.crmDashboard,

    leads: routerLinks.crmLeads,

    requests: routerLinks.crmRequests,

    users: routerLinks.crmUsers,

    reports: routerLinks.crmReports,
  },

  Projects: {
    projects: routerLinks.projectsProjects,

    companies: routerLinks.projectsCompanies,

    memberships: routerLinks.projectsMemberships,

    validities: routerLinks.projectsValidities,
  },
};

export const setRoutesAction = (roles) => async (dispatch) => {
  try {
    // get all pages , user can acess
    var parentNameToFilter = ["CRM", "Properties", "Projects"];
    var AllParentPageName = roles.tree.filter(({ parentName }) =>
      parentNameToFilter.includes(parentName)
    );
    // check if each page have at least have one route  is checked

    var AllParentPageNameChecked = AllParentPageName.filter(({ privilages }) =>
      privilages.some(({ isChecked }) => isChecked)
    );

    // main for each page
    //crm
    //properties
    //projects

    var arrayOfMainNameForEachPage = AllParentPageNameChecked.map(
      ({ parentName, privilages }) => {
        const mainName = privilages.find(
          ({ isChecked }) => isChecked
        ).menueName;

        const toLinksMain = linksRoutesforAllPage[parentName][mainName];

        return {
          key: parentName,
          value: toLinksMain,
        };
      }
    );
    // convert  array to object
    var objectOfMainNameForEachPage = arrayOfMainNameForEachPage.reduce(
      (obj, item) => ({ ...obj, [item.key]: item.value }),
      {}
    );

    // set home "/" to last page => why last => routes
    const nameOfHome =
      AllParentPageNameChecked[AllParentPageNameChecked.length - 1]?.parentName;

    var finalMain = { nameOfHome, ...objectOfMainNameForEachPage };
    dispatch({
      type: TYPES.HOME_MAIN_PAGE,
      payload: { ...finalMain },
    });
    localStorage.setItem("homeMainPage", JSON.stringify(finalMain));

    // save it if refresh happen
    localStorage.setItem(
      "finalSetPages",
      JSON.stringify(AllParentPageNameChecked)
    );

    dispatch({
      type: TYPES.ROUTES_MAIN_PAGES_ALLOWED,
      payload: AllParentPageNameChecked,
    });

    // privilege privilegePages

    // get privilege root
    var PrivilegePageName = roles.tree.filter(
      ({ parentName }) => parentName === "User"
    )[0];

    // get names allowed to user to visit page
    var namePrivilagesAllowedInPage = PrivilegePageName.privilages
      .filter(({ isChecked }) => isChecked)
      .map(({ menueName }) => menueName);

    dispatch({
      type: TYPES.ROUTES_PRIVILEGE_ALLOWED,
      payload: namePrivilagesAllowedInPage,
    });

    localStorage.setItem(
      "privilegePages",
      JSON.stringify(namePrivilagesAllowedInPage)
    ); // save it if refresh happen

    //////////////////////////// sub privlege //////////////////////////
    var subParentNameToFilter = [
      "SubCRMPrivilage",
      "SubPropertiesPrivilage",
      " SubProjectsPrivilage",
    ];


    // get only sub privilege
    var AllSubParentPageName = roles.tree.filter(
      ({ parentName, privilages }) => subParentNameToFilter.includes(parentName)
      // && privilages.some(({ isChecked }) => isChecked)
    );

    // convert array of privilege to object
    const AllSubPrivilageObject = AllSubParentPageName.map(
      ({ privilages, parentName }) => {
        const privilagesObject = privilages.reduce(
          (obj, privilage) => ({
            ...obj,
            [privilage.orderId]: privilage.isChecked,
          }),
          {}
        );
        return { privilages: privilagesObject, parentName: parentName };
      }
    );

    // final to save
    const finalSubParent = subParentNameToFilter.reduce(
      (obj, subName) => ({
        ...obj,
        [subName]: AllSubPrivilageObject.find(
          ({ parentName }) => parentName === subName
        )?.privilages,
      }),
      {}
    );

    dispatch({
      type: TYPES.SUB_ROUTES_PRIVILEGE_ALLOWED,
      payload: finalSubParent,
    });

    localStorage.setItem("subPrivilegePages", JSON.stringify(finalSubParent)); // save it if refresh happen
  } catch (error) {
    console.log(error);
  } finally {
  }
};

// const setRoutesActionProperties =
//   (allPrivilagesProperties) => async (dispatch) => {
//     try {
//       // get all setting in page Properties
//       var allPrivilagesInPage =
//         allPrivilagesProperties.find(
//           ({ parentName }) => parentName === "Properties"
//         )?.privilages || [];

//       // get names allowed to user to visit page
//       var namePrivilagesAllowedInPage = allPrivilagesInPage
//         .filter(({ isChecked }) =>  isChecked)
//         .map(({ menueName }) => menueName);

//       // filter array to send to menu side
//       var privilagesAllowedInPage = sideBarPropertiesLinks.filter((page) =>
//         namePrivilagesAllowedInPage.includes(page.label)
//       );

//       setRoutesPageAllowed(privilagesAllowedInPage);

//       setNameAndMainRoutesPageAllowed({
//         name: namePrivilagesAllowedInPage,
//         main: privilagesAllowedInPage[0]?.to,
//       });
//     } catch (error) {
//     } finally {
//     }
//   };

// var routesAllowed = {
//   name: "Properties",
//   pathPropertiesRedirect: ["/properties/dashboard"],
//   main: false,
//   allowed: ["", "", ""],
// };
