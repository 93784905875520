import {
  LOGIN_WITH_COMPANY,
  LOADING_LOGIN_WITH_COMPANY,
  MESSAGE_LOGIN_WITH_COMPANY,
} from "../types";

const initState = {
  loginWithCompany: JSON.parse(localStorage.getItem("loginWithCompany")) || false,
  loadingLogin: false,
  messageLogin: "",
  res: null
};

const loginWithCompanyReducer = (state = initState , action) => {
  switch (action.type) {

    case LOGIN_WITH_COMPANY:
      return {
        ...state,
        loginWithCompany: action.payload,
      };
    case LOADING_LOGIN_WITH_COMPANY:
      return {
        ...state,
        loadingLogin: action.payload,
      };
    case MESSAGE_LOGIN_WITH_COMPANY:
      return {
        ...state,
        messageLogin: action.payload,
      }
    default:
      return state;
  }
}

export default loginWithCompanyReducer;