import * as TYPES from "../../types";

const initState = {
  Sales: {
    loading: true,
    data: [],
    pageNumber: null,
    pageSize: null,
    totalRecords: 0,
  },
  Meetings: {
    loading: true,
    data: [],
    pageNumber: null,
    pageSize: null,
    totalRecords: 0,
  },
}

const reportsReducer = (state = initState, action) => {
  // console.log("state", state)
  switch(action.type) {
    case TYPES.GET_ALL_REPORTS_BY_SALES:
      return {
        ...state,
        Sales: {
          loading: action.payload.loading,
          data: action.payload.data,
          pageNumber: action.payload.pageNumber,
          pageSize: action.payload.pageSize,
          totalRecords: action.payload.totalRecords,
        },
      };

    case TYPES.GET_ALL_REPORTS_BY_MEETINGS:
      return {
        ...state,
        Meetings: {
          loading: action.payload.loading,
          data: action.payload.data,
          pageNumber: action.payload.pageNumber,
          pageSize: action.payload.pageSize,
          totalRecords: action.payload.totalRecords,
        },
      };

    default:
      return state;
  }
}

export default reportsReducer;