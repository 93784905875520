import { combineReducers } from "redux";

import loginReducer from "./reducers/loginReducer";
import userReducer from "./reducers/userReducer";
import leadReducer from "./reducers/leadReducer";
import sharedReducer from "./reducers/sharedReducer";
import projectsReducer from "./reducers/projects/projectsDialogReducer";
import privilegeReducer from "./reducers/privilegeReducer";
import routesAllowedReducer from "./reducers/routesAllowedReducer";
import listsReducer from "./reducers/listsReducer";
import propertyReducer from "./reducers/propertyReducer";
import aqarFinderReducer from "./reducers/aqarFinderReducer";
import leadCrmReducer from "./reducers/crm/leadCrmReducer";
import dashboardCrmReducer from "./reducers/crm/dashboardCrmReducer";
import projectsCompaniesReducer from "./projects/projects.reducer";

import companiesReducer from "./reducers/projects/companiesReducer";
import loginWithCompanyReducer from "./reducers/loginWithCompanyReducer";
import todoAndDelayReducer from "./reducers/crm/todoAndDelayReducer";
import reportsReducer from "./reducers/crm/reportsReducer";
import requestsReducer from "./reducers/crm/requestsReducer";

export default combineReducers({
  companyName: loginWithCompanyReducer,
  login: loginReducer,
  routesAllowed: routesAllowedReducer,

  shared: sharedReducer,
  lists: listsReducer,
  sideAction: leadReducer,

  userCrm: userReducer,
  leadCrm: leadCrmReducer,
  dashboardCrm: dashboardCrmReducer,
  todoAndDelay: todoAndDelayReducer,
  reports: reportsReducer,
  requests: requestsReducer,

  projects: projectsReducer,
  privilege: privilegeReducer,
  property: propertyReducer,
  aqarFinder: aqarFinderReducer,
  companies: companiesReducer,
  projectsCompany: projectsCompaniesReducer,
});
