/* eslint-disable import/no-anonymous-default-export */
import * as TYPES from "../../types";

const deleteLeadById = (allItems, id) => {
  console.log("allItems", allItems);
  return {
    loading: false,
    data: allItems.filter((lead) => lead?.leadDetailesVM?.id !== id),
  };
};

const editLeadById = (allItems, newData) => {
  const findIndexOfList = allItems.data.findIndex(
    (lead) => lead?.leadDetailesVM?.id === newData?.leadDetailesVM?.id
  );
  const allListToEditOne = allItems;
  allItems.data[findIndexOfList] = newData;

  return [...allListToEditOne.data];
};

const editActionLeadById = (allItems, newDataAction, id) => {
  const findIndexOfList = allItems.data.findIndex(
    (lead) => lead?.leadDetailesVM?.id === id
  );
  const allListToEditOne = allItems;
  var allActionLead = allItems.data[findIndexOfList].actionsData;
  allItems.data[findIndexOfList].actionsData = [
    ...allActionLead,
    newDataAction,
  ];

  return [...allListToEditOne.data];
};

// const addNew = (allItems, newData) => {
//   return [...allItems, newData];
// };

export default (
  state = {
    allLeads: {
      data: [],
      loading: false,
      pageNumber: 1,
      pageSize: 10,
      totalPages: 1,
      totalRecords: 0,
    },
    allPropertiesLead: { data: [], loading: false },
    searchResults: {},
  },

  action
) => {
  // console.log(state.allLeads.data);
  switch (action.type) {
    case TYPES.ALL_LEADS:
      return {
        ...state,
        allLeads: {
          loading: action.payload.loading,
          data: action.payload.data?.data,
          pageNumber: action.payload.data.pageNumber,
          pageSize: action.payload.data.pageSize,
          totalPages: action.payload.data.totalPages,
          totalRecords: action.payload.data.totalRecords,
        },
        // allLeads: action.payload,
      };

    case TYPES.ADD_LEAD:
      return {
        ...state,
        allLeads: {
          ...state.allLeads,
          loading: false,
          data: [...state.allLeads.data, action.payload],
          // data: addNew(state.allLeads.data, action.payload),
        },
      };

    case TYPES.DELETE_LEAD:
      return {
        ...state,
        allLeads: {
          ...state.allLeads,
          data: [...state.allLeads.data?.filter((lead) => lead?.leadDetailesVM?.id !== action.payload)]
        },
      };

    case TYPES.EDIT_LEAD:
      return {
        ...state,
        allLeads: {
          loading: false,
          data: editLeadById(state.allLeads, action.payload),
        },
      };

    case TYPES.EDIT_ACTION_IN_LEAD:
      return {
        ...state,
        allLeads: {
          loading: false,
          data: editActionLeadById(
            state.allLeads,
            action.payload.data,
            action.payload.id
          ),
        },
      };

    case TYPES.ALL_PROPERTIES_LEAD:
      return {
        ...state,
        allPropertiesLead: action.payload,
      };

    case TYPES.SEARCH_RESULTS:
      return {
        ...state,
        searchResults: action.payload,
      };

    default:
      return state;
  }
};
