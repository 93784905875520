const routerLinks = {
  profile: "/profile",
  login: "/login",
  // companyName: "/property-to-client/:id",

  home: "/",
  crm: "/crm",
  crmDashboard: "/crm/dashboard",
  todoList: "/crm/dashboard/todo-list",
  delayList: "/crm/dashboard/delay-list",
  crmLeads: "/crm/leads",
  crmRequests: "/crm/requests",
  crmActivities: "/crm/activities",
  crmUsers: "/crm/users",
  crmReports: "/crm/reports",
  crmSettingLists: "/crm/lists",
  crmSearchResult: "/crm/searchResult",

  showProfile: "/profile",
  productAndPlans: "/product&plans",
  checkout: "/checkout",
  setting: "/setting",
  privilege: "/:page/privilege",
  privilegeUsers: "/:page/privilege/users",
  privilegeBranchs: "/:page/privilege/branchs",
  privilegeRoles: "/:page/privilege/roles",

  properties: "/properties",
  propertiesDashboard: "/properties/dashboard",
  propertiesProperties: "/properties/properties",

  aqarFinder: "/properties/properties/aqar-finder",
  addAqar: "/properties/properties/aqar-finder/add",

  propertiesPropertiesAdd: "/properties/properties/add",
  propertiesPropertiesDetails: "/properties/properties/details/:id",
  showPropertiesToClient: "/properties/properties/details/:id/show-to-client",
  propertiesPropertiesRequests: "/properties/properties/requests",

  propertiesDetails: "/properties/DetailsProperties",
  propertiesUpdates: "/properties/updates",
  propertiesOwners: "/properties/owners",
  propertiesSettingLists: "/properties/lists",
  propertiesDetailsUnAuth: "/properties/details/ID=:id&tenantCode=:tenantCode",
  multiProperty: "/properties/multi-property/tenantCode=:tenantCode&codes=:codes",

  projects: "/projects",
  projectsProjects: "/projects/projects",
  projectsCompanies: "/projects/companies",
  projectsMemberships: "/projects/memberships",
  projectsValidities: "/projects/validities",
  projectsSettings: "/projects/settings",
};

export default routerLinks;
