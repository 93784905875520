import React, { lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import Loading from "../common/loading/Loading";
// import NotFoundPage from "../pages/page-not-found/NotFoundPage";
import routerLinks from "./routerLinks";
import PrivateRoute from "./PrivateRoute";
import DetailsUnAuth from "../pages/details-unAuth/DetailsUnAuth";
import MultiProperty from "../pages/multi-property/MultiProperty";
// import CompanyName from "../pages/login/company-name/CompanyName";
//import PrivilegeRoutes from "./PrivilegeRoutes";

const Login = lazy(() => import("../pages/login/Login"));
const CRM = lazy(() => import("../pages/crm/CRM"));
const Properties = lazy(() => import("../pages/properties/Properties"));
const Projects = lazy(() => import("../pages/projects/Projects"));
const Home = lazy(() => import("../pages/home/Home"));

const allRoutesPages = {
  CRM: {
    path: routerLinks.crm,
    name: "CRM",
    component: CRM,
  },
  Projects: {
    path: routerLinks.projects,
    name: "Projects",
    component: Projects,
  },
  Properties: {
    path: routerLinks.properties,
    name: "Properties",
    component: Properties,
  },
};

const Routes = () => {
  const login = useSelector(state => state.login);
  const routesAllowed = useSelector(state => state.routesAllowed);

  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route exact path={routerLinks.login} component={Login} />
        <Route
          exact
          path={routerLinks.propertiesDetailsUnAuth}
          component={DetailsUnAuth}
        />
        <Route
          exact
          path={routerLinks.multiProperty}
          component={MultiProperty}
        />

        {routesAllowed.routesMainPagesAllowed.length === 0
          ? routesAllowed.routesPrivilegeAllowed.length > 0 && (
              <PrivateRoute
                authed={login.login}
                path={routerLinks.home}
                component={Home}
              />
            )
          : React.Children.toArray(
              routesAllowed.routesMainPagesAllowed.map(({ parentName }) => (
                <PrivateRoute
                authed={login.login}
                path={
                  routesAllowed.homeMainPage.nameOfHome === parentName
                  ? "/"
                  : allRoutesPages[parentName].path
                }
                component={allRoutesPages[parentName].component}
                />
              ))
            )}

        <PrivateRoute authed={login.login} component={Login} path={"/"} />

        {/* <PrivateRoute
          authed={login.login}
          path={routerLinks.properties}
          component={Properties}
        /> */}

        {/* <PrivateRoute
          authed={login.login}
          path={routerLinks.home}
          component={CRM}
        /> */}

        {/* <Route path={routerLinks.projects} component={Projects} />

        <Route path={routerLinks.properties} component={Properties} />

        <Route path={routerLinks.home} component={CRM} /> */}

        {/* not found page */}
        {/* <Route path="*" component={NotFoundPage} /> */}
      </Switch>
    </Suspense>
  );
};

export default Routes;

// import React, { lazy, Suspense } from "react";
// import { useSelector } from "react-redux";
// import { Route, Switch } from "react-router-dom";
// import Loading from "../common/loading/Loading";
// import NotFoundPage from "../pages/page-not-found/NotFoundPage";
// import routerLinks from "./routerLinks";
// import PrivateRoute from "./PrivateRoute";

// const Login = lazy(() => import("../pages/login/Login"));
// const CRM = lazy(() => import("../pages/crm/CRM"));
// const Properties = lazy(() => import("../pages/properties/Properties"));
// const Projects = lazy(() => import("../pages/projects/Projects"));

// const routesComponent = {
//   Projects: Projects,
//   Properties: Properties,
//   CRM: CRM,
// };

// const Routes = () => {
//   const login = useSelector((state) => state.login);
//   const routesAllowed = useSelector((state) => state.routesAllowed);

//   return (
//     <Suspense fallback={<Loading />}>
//       <Switch>
//         <Route exact path={routerLinks.login} component={Login} />

//         {React.Children.toArray(
//           routesAllowed?.routesMainPagesAllowed.map(({ to, name, main }) => (
//             <PrivateRoute
//               authed={login.login}
//               path={main ? "/" : to}
//               component={routesComponent[name]}
//             />
//           ))
//         )}

//         {/* <PrivateRoute
//           authed={login.login}
//           path={routerLinks.properties}
//           component={Properties}
//         /> */}

//         {/* <PrivateRoute
//           authed={login.login}
//           path={routerLinks.home}
//           component={CRM}
//         /> */}

//         {/* <Route path={routerLinks.projects} component={Projects} />

//         <Route path={routerLinks.properties} component={Properties} />

//         <Route path={routerLinks.home} component={CRM} /> */}

//         {/* not found page */}
//         <Route path="*" component={NotFoundPage} />
//       </Switch>
//     </Suspense>
//   );
// };

// export default Routes;
