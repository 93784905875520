/* eslint-disable import/no-anonymous-default-export */
import * as TYPES from "../types";

export default (
  state = {
    login: !!localStorage.getItem("Authorization"),
    informationsUser: JSON.parse(localStorage.getItem("informationUser")) || {},
    loadingLogin: false,
    messageLogin: "",
    messageUnautherizedLogin: {},
  },

  action
) => {
  switch (action.type) {
    case TYPES.LOGIN:
      return {
        ...state,
        login: action.payload,
      };

    case TYPES.LOADING_LOGIN:
      return {
        ...state,
        loadingLogin: action.payload,
      };

    case TYPES.MESSAGE_LOGIN:
      return {
        ...state,
        messageLogin: action.payload,
      };

    case TYPES.MESSAGE_UNAUTHORIZED_LOGIN:
      return {
        ...state,
        messageUnautherizedLogin: { ...action.payload },
      };

    case TYPES.INFORMATIONS_USER:
      return {
        ...state,
        informationsUser: action.payload,
      };

    default:
      return state;
  }
};
