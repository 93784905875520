import axios from "axios";

export const instance = axios.create({
  baseURL: "https://crmbackend.aqar-cloud.com/api/",
  // baseURL: "http://crmbackend.aqaratvip.com/api/",
});

instance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    const token = localStorage.getItem("Authorization");
    const companyName = localStorage.getItem("companyName");
    config.headers["Authorization"] = token;
    config.headers["tenant-code"] = companyName;
    config.headers["content-type"] = "multipart/form-data";
    config.headers["Access-Control-Allow-Origin"] = "*";

    return config;
  },

  function (error) {
    // Do something with request error

    return Promise.reject(error);
  }
);

// Add a response interceptor
// instance.interceptors.response.use(
//   function (response) {
//     // Do something with response data
//     return response;
//   },
//   function (error) {
//     // Do something with response error
//     console.log(error);
//     const dispatch = useDispatch();
//     if (error.response?.status === 401 || error.response?.status === 403)
//       dispatch(setUnautherizedMessageAction());
//     return Promise.reject(error);
//   }
// );
