import "./Loading.scss";
import React from "react";

import loadingImg from "../../assets/imgs/loading.gif";

const Loading = () => {
  return (
    <div className="loadingPage">
      <img src={loadingImg} alt="Loading" />
    </div>
  );
};

export default Loading;
