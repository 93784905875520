import React, { useEffect } from 'react';
import "./style.scss";
import LocationIcon from "../../common/icons/LocationIcon";
import {
  WhatsAppOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import SpaceIcon from '../../common/icons/SpaceIcon';
import RoomIcon from '../../common/icons/RoomIcon';
import BathIcon from '../../common/icons/BathIcon';
import ImageIcon from '../../common/icons/ImageIcon';
import { useDispatch, useSelector } from 'react-redux';
import { getMultiPropertyAction } from "../../store/actions/propertyAction/detailsPropertyAction.js"
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Spin } from 'antd';
import { Link } from "react-router-dom"


export const MultiPropertyItem = ({ property }) => {
  return (
    <div className="multi-prop__list__item">
      <div className="multi-prop__list__item__images">
        <img
          src={property.images?.length <= 0 ? "https://images.unsplash.com/photo-1501183638710-841dd1904471?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80" : property.images[0]}
          alt=""
        />
        <div className="count-imgs">
          <span>{property.images.length}</span>
          <ImageIcon width={15} />
        </div>
      </div>
      <div className="multi-prop__list__item__details">
        <p className="price">
          <h2>
            <span>{property.proprtyDetailesVM.price}</span>
            <span>جنيه</span>
          </h2>
        </p>
        <p className="title">
          {property.proprtyDetailesVM.title}
        </p>
        <div className="location">
          <LocationIcon color="#333" width={10} bgColor={"#616161"} />
          <span>{property.proprtyDetailesVM.propertyaddress || `${property.proprtyDetailesVM.cityname + " - " + property.proprtyDetailesVM.areaname}`}</span>
        </div>
        <div className="property-details">
          <div className="space">
            <SpaceIcon color="#333" width={15} bgColor={"#616161"} />
            <span> {property.proprtyDetailesVM.space} متر²</span>
          </div>
          <div className="rooms">
            <RoomIcon color="#333" width={15} bgColor={"#616161"} />
            <span>${property.proprtyDetailesVM.roomsname || 0}</span>
          </div>
          <div className="bathrooms">
            <BathIcon color="#333" width={15} bgColor={"#616161"} />
            <span>{property.proprtyDetailesVM.bathroomsname || 0}</span>
          </div>
        </div>
        <div className="sales">
          <p className="name">{property.proprtyDetailesVM.username}</p>
          <div className="phone-icons">
            <a
              href={`https://wa.me/+${property.proprtyDetailesVM.usernphone}`}
              target="_blank"
              rel="noreferrer"
            >
              <WhatsAppOutlined />
            </a>
            <a href={`tel:+${property.proprtyDetailesVM.usernphone}`} rel="noreferrer">
              <PhoneOutlined />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

const MultiProperty = () => {
  const dispatch = useDispatch();

  const {
    data,
    loadingPage,
  } = useSelector((state) => state.property.multiProperty);
  
  const { codes, tenantCode } = useParams();
  
  useEffect(() => {
    dispatch(getMultiPropertyAction(codes, tenantCode))
  }, [codes, dispatch, tenantCode])

  return (
    <Spin spinning={loadingPage}>
      <div className="multi-prop">
        <div className="container">
          <div className="title">
            <h3>Properties</h3>
          </div>

          <div className="multi-prop__list">
            {
              data?.map((property) => (
                <a 
                  href={`/properties/details/ID=${property.proprtyDetailesVM.id}&tenantCode=${tenantCode}`} 
                  key={property.proprtyDetailesVM.id}
                  target='_blank' 
                  rel="noreferrer"
                >
                  <MultiPropertyItem property={property} />
                </a>
              ))
            }
          </div>
        </div>
      </div>
    </Spin>
  );
}

export default MultiProperty