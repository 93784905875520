/* eslint-disable import/no-anonymous-default-export */
import * as TYPES from "../types";

export default (
  state = {
    dialogUser: {},
  },

  action
) => {
  switch (action.type) {
    case TYPES.DIALOG_USER:
      return {
        ...state,
        dialogUser: action.payload,
      };

    default:
      return state;
  }
};
