/* eslint-disable import/no-anonymous-default-export */
import * as TYPES from "../types";

const deleteById = (allItems, id) => {
  return {
    loading: false,
    data: allItems.data.filter((list) => list.id !== id),
  };
};

const editById = (allItems, newData) => {
  const findIndexOfList = allItems.data.findIndex(
    (list) => list.id === newData.id
  );
  const allListToEditOne = allItems;
  allItems.data[findIndexOfList] = newData;

  return [...allListToEditOne.data];
};

const addNew = (allItems, newData) => {
  return [...allItems.data, newData];
};

//
export default (
  state = {
    addEditLists: {},

    // common

    // contry
    Country: { data: [], loading: false },
    //city
    City: { data: [], loading: false },
    //area
    Area: { data: [], loading: false },
    // district
    District: { data: [], loading: false },
    // region
    Region: { data: [], loading: false },
    // next action
    "Next Action": { data: [], loading: false },

    // code
    "Active Code": { data: [], loading: false },
    // main countrt
    mainCountry: null,

    ////// for crm only
    // leadStatus
    "Lead Status": { data: [], loading: false },
    // marketingChannel
    "Marketing Channel": { data: [], loading: false },
    // communication
    Communication: { data: [], loading: false },
    // cancelReasion
    "Cancel Reasion": { data: [], loading: false },

    /////// for properties only
    //Rooms
    Rooms: { data: [], loading: false },
    //Bathrooms
    Bathrooms: { data: [], loading: false },
    //Floor
    Floor: { data: [], loading: false },
    //Finishing Type
    "Finishing Type": { data: [], loading: false },
    //Property Type
    "Property Type": { data: [], loading: false },
    //STATUS
    Status: { data: [], loading: false },
    //Factory License
    "Factory License": { data: [], loading: false },

    //Property
    Property: { data: [], loading: false },
  },

  action
) => {
  switch (action.type) {
    case TYPES.ADD_EDIT_LISTS:
      return {
        ...state,
        addEditLists: action.payload,
      };

    // case TYPES.LOADING_PAGE_ADD_EDIT_LISTS:
    //   return {
    //     ...state,
    //     addEditLists: { ...state.addEditLists, loadingPage: action.payload },
    //   };

    case TYPES.CHANGE_LOADING_MESSAGE_DIALOG_LISTS:
      return {
        ...state,
        addEditLists: { ...state.addEditLists, ...action.payload },
      };

    // COUNTRY
    case TYPES.ALL_COUNTRY_LISTS:
      return {
        ...state,
        Country: action.payload,
      };

    case TYPES.ADD_COUNTRY_LISTS:
      return {
        ...state,
        Country: {
          loading: false,
          data: addNew(state.Country, action.payload),
        },
      };

    case TYPES.DELETE_COUNTRY_LISTS:
      return {
        ...state,
        Country: deleteById(state.Country, action.payload),
      };

    case TYPES.EDIT_COUNTRY_LISTS:
      return {
        ...state,
        Country: {
          loading: false,
          data: editById(state.Country, action.payload),
        },
      };

    // CITY
    case TYPES.ALL_CITY_LISTS:
      return {
        ...state,
        City: action.payload,
      };

    case TYPES.DELETE_CITY_LISTS:
      return {
        ...state,
        City: deleteById(state.City, action.payload),
      };

    case TYPES.EDIT_CITY_LISTS:
      return {
        ...state,
        City: {
          loading: false,
          data: editById(state.City, action.payload),
        },
      };

    case TYPES.ADD_CITY_LISTS:
      return {
        ...state,
        City: {
          loading: false,
          data: addNew(state.City, action.payload),
        },
      };
    //AREA
    case TYPES.ALL_AREA_LISTS:
      return {
        ...state,
        Area: action.payload,
      };

    case TYPES.DELETE_AREA_LISTS:
      return {
        ...state,
        Area: deleteById(state.Area, action.payload),
      };

    case TYPES.EDIT_AREA_LISTS:
      return {
        ...state,
        Area: {
          loading: false,
          data: editById(state.Area, action.payload),
        },
      };

    case TYPES.ADD_AREA_LISTS:
      return {
        ...state,
        Area: {
          loading: false,
          data: addNew(state.Area, action.payload),
        },
      };

    //District
    case TYPES.ALL_DISTRICT_LISTS:
      return {
        ...state,
        District: action.payload,
      };

    case TYPES.DELETE_DISTRICT_LISTS:
      return {
        ...state,
        District: deleteById(state.District, action.payload),
      };

    case TYPES.EDIT_DISTRICT_LISTS:
      return {
        ...state,
        District: {
          loading: false,
          data: editById(state.District, action.payload),
        },
      };

    case TYPES.ADD_DISTRICT_LISTS:
      return {
        ...state,
        District: {
          loading: false,
          data: addNew(state.District, action.payload),
        },
      };

    //Region
    case TYPES.ALL_REGION_LISTS:
      return {
        ...state,
        Region: action.payload,
      };

    case TYPES.DELETE_REGION_LISTS:
      return {
        ...state,
        Region: deleteById(state.Region, action.payload),
      };

    case TYPES.EDIT_REGION_LISTS:
      return {
        ...state,
        Region: {
          loading: false,
          data: editById(state.Region, action.payload),
        },
      };

    case TYPES.ADD_REGION_LISTS:
      return {
        ...state,
        Region: {
          loading: false,
          data: addNew(state.Region, action.payload),
        },
      };

    //Rooms
    case TYPES.ALL_ROOMS_LISTS:
      return {
        ...state,
        Rooms: action.payload,
      };

    case TYPES.ADD_ROOMS_LISTS:
      return {
        ...state,
        Rooms: {
          loading: false,
          data: addNew(state.Rooms, action.payload),
        },
      };

    case TYPES.DELETE_ROOMS_LISTS:
      return {
        ...state,
        Rooms: deleteById(state.Rooms, action.payload),
      };

    case TYPES.EDIT_ROOMS_LISTS:
      return {
        ...state,
        Rooms: {
          loading: false,
          data: editById(state.Rooms, action.payload),
        },
      };

    //Bathrooms
    case TYPES.ALL_BATHROOMS_LISTS:
      return {
        ...state,
        Bathrooms: action.payload,
      };

    case TYPES.ADD_BATHROOMS_LISTS:
      return {
        ...state,
        Bathrooms: {
          loading: false,
          data: addNew(state.Bathrooms, action.payload),
        },
      };

    case TYPES.DELETE_BATHROOMS_LISTS:
      return {
        ...state,
        Bathrooms: deleteById(state.Bathrooms, action.payload),
      };

    case TYPES.EDIT_BATHROOMS_LISTS:
      return {
        ...state,
        Bathrooms: {
          loading: false,
          data: editById(state.Bathrooms, action.payload),
        },
      };

    //Floor
    case TYPES.ALL_FLOOR_LISTS:
      return {
        ...state,
        Floor: action.payload,
      };

    case TYPES.ADD_FLOOR_LISTS:
      return {
        ...state,
        Floor: {
          loading: false,
          data: addNew(state.Floor, action.payload),
        },
      };

    case TYPES.DELETE_FLOOR_LISTS:
      return {
        ...state,
        Floor: deleteById(state.Floor, action.payload),
      };

    case TYPES.EDIT_FLOOR_LISTS:
      return {
        ...state,
        Floor: {
          loading: false,
          data: editById(state.Floor, action.payload),
        },
      };

    // Finishing type
    case TYPES.ALL_FINISHING_TYPE_LISTS:
      return {
        ...state,
        "Finishing Type": action.payload,
      };

    case TYPES.ADD_FINISHING_TYPE_LISTS:
      return {
        ...state,
        "Finishing Type": {
          loading: false,
          data: addNew(state["Finishing Type"], action.payload),
        },
      };

    case TYPES.DELETE_FINISHING_TYPE_LISTS:
      return {
        ...state,
        "Finishing Type": deleteById(state["Finishing Type"], action.payload),
      };

    case TYPES.EDIT_FINISHING_TYPE_LISTS:
      return {
        ...state,
        "Finishing Type": {
          loading: false,
          data: editById(state["Finishing Type"], action.payload),
        },
      };

    // Property Type
    case TYPES.ALL_PROPERTY_TYPE_LISTS:
      return {
        ...state,
        "Property Type": action.payload,
      };

    case TYPES.ADD_PROPERTY_TYPE_LISTS:
      return {
        ...state,
        "Property Type": {
          loading: false,
          data: addNew(state["Property Type"], action.payload),
        },
      };

    case TYPES.DELETE_PROPERTY_TYPE_LISTS:
      return {
        ...state,
        "Property Type": deleteById(state["Property Type"], action.payload),
      };

    case TYPES.EDIT_PROPERTY_TYPE_LISTS:
      return {
        ...state,
        "Property Type": {
          loading: false,
          data: editById(state["Property Type"], action.payload),
        },
      };

    //Property
    case TYPES.ALL_PROPERTY_LISTS:
      return {
        ...state,
        Property: action.payload,
      };

    //code
    case TYPES.ACTIVE_CODE:
      return {
        ...state,
        "Active Code": action.payload,
      };

    case TYPES.MAIN_COUNTRY:
      return {
        ...state,
        mainCountry: action.payload,
      };

    // Lead Status
    case TYPES.ALL_LEAD_STATUS_LISTS:
      return {
        ...state,
        "Lead Status": action.payload,
      };

    case TYPES.ADD_LEAD_STATUS_LISTS:
      return {
        ...state,
        "Lead Status": {
          loading: false,
          data: addNew(state["Lead Status"], action.payload),
        },
      };

    case TYPES.DELETE_LEAD_STATUS_LISTS:
      return {
        ...state,
        "Lead Status": deleteById(state["Lead Status"], action.payload),
      };

    case TYPES.EDIT_LEAD_STATUS_LISTS:
      return {
        ...state,
        "Lead Status": {
          loading: false,
          data: editById(state["Lead Status"], action.payload),
        },
      };

    // Marketing Channel
    case TYPES.ALL_MARKETING_CHANNEL_LISTS:
      return {
        ...state,
        "Marketing Channel": action.payload,
      };

    case TYPES.ADD_MARKETING_CHANNEL_LISTS:
      return {
        ...state,
        "Marketing Channel": {
          loading: false,
          data: addNew(state["Marketing Channel"], action.payload),
        },
      };

    case TYPES.DELETE_MARKETING_CHANNEL_LISTS:
      return {
        ...state,
        "Marketing Channel": deleteById(
          state["Marketing Channel"],
          action.payload
        ),
      };

    case TYPES.EDIT_MARKETING_CHANNEL_LISTS:
      return {
        ...state,
        "Marketing Channel": {
          loading: false,
          data: editById(state["Marketing Channel"], action.payload),
        },
      };

    // Communication
    case TYPES.ALL_COMMUNICATION_LISTS:
      return {
        ...state,
        Communication: action.payload,
      };

    case TYPES.ADD_COMMUNICATION_LISTS:
      return {
        ...state,
        Communication: {
          loading: false,
          data: addNew(state["Communication"], action.payload),
        },
      };

    case TYPES.DELETE_COMMUNICATION_LISTS:
      return {
        ...state,
        Communication: deleteById(state["Communication"], action.payload),
      };

    case TYPES.EDIT_COMMUNICATION_LISTS:
      return {
        ...state,
        Communication: {
          loading: false,
          data: editById(state["Communication"], action.payload),
        },
      };

    // Cancel Reasion
    case TYPES.ALL_CANCEL_REASION_LISTS:
      return {
        ...state,
        "Cancel Reasion": action.payload,
      };

    case TYPES.ADD_CANCEL_REASION_LISTS:
      return {
        ...state,
        "Cancel Reasion": {
          loading: false,
          data: addNew(state["Cancel Reasion"], action.payload),
        },
      };

    case TYPES.DELETE_CANCEL_REASION_LISTS:
      return {
        ...state,
        "Cancel Reasion": deleteById(state["Cancel Reasion"], action.payload),
      };

    case TYPES.EDIT_CANCEL_REASION_LISTS:
      return {
        ...state,
        "Cancel Reasion": {
          loading: false,
          data: editById(state["Cancel Reasion"], action.payload),
        },
      };

    //Status
    case TYPES.ALL_STATUS_LISTS:
      return {
        ...state,
        Status: action.payload,
      };

    case TYPES.ADD_STATUS_LISTS:
      return {
        ...state,
        Status: {
          loading: false,
          data: addNew(state.Status, action.payload),
        },
      };

    case TYPES.EDIT_STATUS_LISTS:
      return {
        ...state,
        Status: {
          loading: false,
          data: editById(state.Status, action.payload),
        },
      };

    case TYPES.DELETE_STATUS_LISTS:
      return {
        ...state,
        Status: deleteById(state.Status, action.payload),
      };

    //NEXT_ACTION
    case TYPES.ALL_NEXT_ACTION_LISTS:
      return {
        ...state,
        "Next Action": action.payload,
      };

    case TYPES.ADD_NEXT_ACTION_LISTS:
      return {
        ...state,
        "Next Action": {
          loading: false,
          data: addNew(state["Next Action"], action.payload),
        },
      };

    case TYPES.EDIT_NEXT_ACTION_LISTS:
      return {
        ...state,
        "Next Action": {
          loading: false,
          data: editById(state["Next Action"], action.payload),
        },
      };

    case TYPES.DELETE_NEXT_ACTION_LISTS:
      return {
        ...state,
        "Next Action": deleteById(state["Next Action"], action.payload),
      };

    //Factory License
    case TYPES.ALL_FACTORY_LICENSE_LISTS:
      return {
        ...state,
        "Factory License": action.payload,
      };

    case TYPES.ADD_FACTORY_LICENSE_LISTS:
      return {
        ...state,
        "Factory License": {
          loading: false,
          data: addNew(state["Factory License"], action.payload),
        },
      };

    case TYPES.EDIT_FACTORY_LICENSE_LISTS:
      return {
        ...state,
        "Factory License": {
          loading: false,
          data: editById(state["Factory License"], action.payload),
        },
      };

    case TYPES.DELETE_FACTORY_LICENSE_LISTS:
      return {
        ...state,
        "Factory License": deleteById(state["Factory License"], action.payload),
      };

    default:
      return state;
  }
};
