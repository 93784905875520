/* eslint-disable import/no-anonymous-default-export */
import * as TYPES from "../types";

export default (
  state = {
    warningDialog: {},
    sideBar: false,
    settingWindow: false,
    viewMessage: {},
    branch: null,
    loadingAllPage: false,
    rowSelection: {
      aqarFinder: {
        start: 0,
        end: 0,
      },
    },
  },

  action
) => {
  switch (action.type) {
    case TYPES.WARNING_DIALOG:
      return {
        ...state,
        warningDialog: action.payload,
      };

    case TYPES.VIEW_MESSAGE:
      return {
        ...state,
        viewMessage: action.payload,
      };

    case TYPES.SIDE_BAR:
      return {
        ...state,
        sideBar: action.payload,
      };

    case TYPES.SETTING_WINDOW:
      return {
        ...state,
        settingWindow: action.payload,
      };

    case TYPES.BRANCH:
      return {
        ...state,
        branch: action.payload,
      };
    case TYPES.LOADING_ALL_PAGE:
      return {
        ...state,
        loadingAllPage: action.payload,
      };
    case TYPES.START_AND_END_ROW_SELECTION:
      return {
        ...state,
        rowSelection: action.payload,
      };

    default:
      return state;
  }
};
