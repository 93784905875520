/* eslint-disable import/no-anonymous-default-export */
import * as TYPES from "../types";

const deleteById = (allItems, id, nameId = "id") => {
  return {
    loading: false,
    data: allItems.data.filter((list) => list[nameId] !== id),
  };
};

const editById = (allItems, newData, nameOfId = "id") => {
  const findIndexOfList = allItems.data.findIndex(
    (list) => list[nameOfId] === newData[nameOfId]
  );
  const allListToEditOne = allItems;
  allItems.data[findIndexOfList] = newData;

  return [...allListToEditOne.data];
};

const addNew = (allItems, newData) => {
  return [...allItems.data, newData];
};

//
export default (
  state = {
    addEditUser: {},
    addEditRole: {},
    addEditBranch: {},
    // allPrivilige
    allPrivilage: { data: [], loading: false },
    // user
    allUsers: { data: [], loading: false },
    //branch
    allBranchs: { data: [], loading: false },
    //
    allUserBranchs: { data: [], loading: false },
    //roles
    allRoles: { data: [], loading: false },

    detailsOneRole: {
      defaultValue: {
        roleName: null,
        roles: [],
        branchs: [],
      },
      loading: false,
    },
    user: {
      data: [],
      loading: false,
      message: null
    }
  },
  action
) => {
  switch (action.type) {
    case TYPES.ADD_EDIT_USER:
      return {
        ...state,
        addEditUser: action.payload,
      };

    case TYPES.ADD_EDIT_ROLE:
      return {
        ...state,
        addEditRole: action.payload,
      };

    case TYPES.ADD_EDIT_BRANCH:
      return {
        ...state,
        addEditBranch: action.payload,
      };

    // users

    case TYPES.ALL_USERS_PRIVILEGE:
      return {
        ...state,
        allUsers: action.payload,
      };

    case TYPES.ADD_USER_PRIVILEGE:
      return {
        ...state,
        allUsers: {
          loading: false,
          data: addNew(state.allUsers, action.payload),
        },
      };

    case TYPES.EDIT_USER_PRIVILEGE:
      return {
        ...state,
        allUsers: {
          loading: false,
          data: editById(state.allUsers, action.payload, "userID"),
        },
      };

    case TYPES.CHANGE_LOADING_MESSAGE_DIALOG_USER:
      return {
        ...state,
        addEditUser: { ...state.addEditUser, ...action.payload },
      };

    case TYPES.DELETE_USER_PRIVILEGE:
      return {
        ...state,
        allUsers: deleteById(state.allUsers, action.payload, "userID"),
      };

    // user branch

    case TYPES.ALL_USER_BRANCHS:
      return {
        ...state,
        allUserBranchs: action.payload,
      };

    //branchs
    case TYPES.ALL_BRANCHS_PRIVILEGE:
      return {
        ...state,
        allBranchs: action.payload,
      };

    case TYPES.CHANGE_LOADING_MESSAGE_DIALOG_BRANCH:
      return {
        ...state,
        addEditBranch: { ...state.addEditBranch, ...action.payload },
      };

    case TYPES.DELETE_BRANCH_PRIVILEGE:
      return {
        ...state,
        allBranchs: deleteById(state.allBranchs, action.payload),
      };

    case TYPES.EDIT_BRANCH_PRIVILEGE:
      return {
        ...state,
        allBranchs: {
          loading: false,
          data: editById(state.allBranchs, action.payload),
        },
      };

    //ROLES
    case TYPES.ALL_ROLES_PRIVILEGE:
      return {
        ...state,
        allRoles: action.payload,
      };

    case TYPES.DELETE_ROLE_PRIVILEGE:
      return {
        ...state,
        allRoles: deleteById(state.allRoles, action.payload),
      };

    case TYPES.CHANGE_LOADING_MESSAGE_DIALOG_ROLE:
      return {
        ...state,
        addEditRole: { ...state.addEditRole, ...action.payload },
      };

    case TYPES.DETAILS_ONE_ROLE:
      return {
        ...state,
        detailsOneRole: action.payload,
      };

    case TYPES.ALL_PRIVILEGE:
      return {
        ...state,
        allPrivilage: action.payload,
      };

    case TYPES.GET_USER_BY_ID:
      return {
        ...state,
        user: {
          data: action.payload.user,
          loading: action.payload.loading,
          message: action.payload.message
        }
      }

    default:
      return state;
  }
};
