// ================ Login With Company ================
export const LOGIN_WITH_COMPANY = "COMPANY_NAME";
export const MESSAGE_LOGIN_WITH_COMPANY = 'MESSAGE_LOGIN_WITH_COMPANY';
export const LOADING_LOGIN_WITH_COMPANY = "LOADING_LOGIN_WITH_COMPANY";
export const BACK_TO_LOGIN_W_COMPANY_PAGE = "BACK_TO_LOGIN_W_COMPANY_PAGE";
// ================= Login =================
export const LOGIN = "LOGIN";
export const MESSAGE_LOGIN = "MESSAGE_LOGIN";
export const LOADING_LOGIN = "LOADING_LOGIN";
export const MESSAGE_UNAUTHORIZED_LOGIN = "MESSAGE_UNAUTHORIZED_LOGIN";
export const INFORMATIONS_USER = "INFORMATIONS_USER";
// ================= User =================
export const DIALOG_USER = "DIALOG_USER";
export const SIDE_ACTIONS = "SIDE_ACTIONS";
export const EDIT_DEFAULT_VALUE_SIDE_ACTIONS =
  "EDIT_DEFAULT_VALUE_SIDE_ACTIONS";
export const EDIT_DEFAULT_VALUE_COMMENT_SIDE_ACTIONS =
  "EDIT_DEFAULT_VALUE_COMMENT_SIDE_ACTIONS";
export const CHANGE_LOADING_MESSAGE_SIDE = "CHANGE_LOADING_MESSAGE_SIDE";
export const GET_USER_BY_ID = "GET_USER_BY_ID";

// =================== Shared ===================
export const WARNING_DIALOG = "WARNING_DIALOG";
export const SIDE_BAR = "SIDE_BAR";
export const SETTING_WINDOW = "SETTING_WINDOW";
export const VIEW_MESSAGE = "VIEW_MESSAGE";
export const BRANCH = "BRANCH";
export const LOADING_ALL_PAGE = "LOADING_ALL_PAGE";
export const START_AND_END_ROW_SELECTION = "START_AND_END_ROW_SELECTION";


// =================== Owner ===================
export const ALL_OWNERS = "ALL_OWNERS";
export const ADD_OWNER = "ADD_OWNER";
export const EDIT_OWNER = "EDIT_OWNER";
export const DELETE_OWNER = "DELETE_OWNER";
export const SEARCH_BY_PHONE = "SEARCH_BY_PHONE";
export const SEARCH_BY_ADDED_PHONE = "SEARCH_BY_ADDED_PHONE";
export const OWNER_PROPERTIES = "OWNER_PROPERTIES";
export const GET_NEW_OWNER_ID = "GET_NEW_OWNER_ID";
export const GET_OWNERS_BY_NAME_OR_PHONE = "GET_OWNERS_BY_NAME_OR_PHONE";
export const GET_OWNER_BY_ID = "GET_OWNER_BY_ID";

//property
export const ALL_PROPERTIES = "ALL_PROPERTIES";
export const ADD_PROPERTY = "ADD_PROPERTY";
export const EDIT_PROPERTY = "EDIT_PROPERTY";
export const DELETE_PROPERTY = "DELETE_PROPERTY";
export const ADD_EDIT_PROPERTY_PAGE = "ADD_EDIT_PROPERTY_PAGE";
export const CHANGE_LOADING_MESSAGE_ADD_EDIT_PROPERTY_PAGE =
  "CHANGE_LOADING_MESSAGE_ADD_EDIT_PROPERTY_PAGE";
export const SET_PAGE_NUMBER_AND_PAGE_SIZE = "SET_PAGE_NUMBER_AND_PAGE_SIZE";
export const GET_LOCATION_TO_MAP = "GET_LOCATION_TO_MAP";
export const GET_DETAILS_PROPERTY_UNAUTH = "GET_DETAILS_PROPERTY_UNAUTH";
export const GET_MULTI_PROPERTY_UNAUTH = "GET_MULTI_PROPERTY_UNAUTH";
// aqar finder
export const ALL_AQAR_FINDER_TABS = "ALL_AQAR_FINDER_TABS";
export const AQAR_FINDER_ADD = "AQAR_FINDER_ADD";
export const GET_AQAR_FINDER_PROPERTIES = "GET_AQAR_FINDER_PROPERTIES";
export const SET_PAGE_NUM_AND_PAGE_SIZE_AQAR_FINDER =
  "SET_PAGE_NUM_AND_PAGE_SIZE_AQAR_FINDER";
export const EDIT_AQAR_FINDER = "EDIT_AQAR_FINDER";
// get locations to  filter property by text
export const ALL_LOCATION_BY_TEXT = "ALL_LOCATION_BY_TEXT";

// details property
export const DETAILS_PROPERTY = "DETAILS_PROPERTY";
export const CHANGE_CASE_RENT_OR_SOLD = "CHANGE_CASE_RENT_OR_SOLD";
export const CHANGE_STATUS_IN_DETAILS = "CHANGE_STATUS_IN_DETAILS";

export const ADD_EDIT_REQUEST = "ADD_EDIT_REQUEST";
export const CHANGE_LOADING_MESSAGE_DIALOG_REQUEST =
  "CHANGE_LOADING_MESSAGE_DIALOG_REQUEST";

export const EXPORT_PROPERTIES = "EXPORT_PROPERTIES";
export const GET_DOWNLOAD_PROPERTY_IMAGES = "GET_DOWNLOAD_PROPERTY_IMAGES";
// requests
export const ALL_REQUEST = "ALL_REQUEST";
export const ALL_ACTIONS = "ALL_ACTIONS";
export const EDIT_REQUEST = "EDIT_REQUEST";

// updates
export const ALL_UPDATES = "ALL_UPDATES";
export const EDIT_UPDATE = "EDIT_UPDATE";
export const DELETE_UPDATE = "DELETE_UPDATE";
export const SET_PAGE_NUM_AND_PAGE_SIZE_UPDATES = "SET_PAGE_NUM_AND_PAGE_SIZE_UPDATES"

// Dashboard
export const ALL_DASHBOARD = "ALL_DASHBOARD";
export const GET_DATE_AND_DATE_TYPE = "GET_DATE_AND_DATE_TYPE";
export const GET_TODO_LIST = "GET_TODO_LIST";
export const GET_DELAY_LIST = "GET_DELAY_LIST";
export const SET_PAGE_NUMBER_AND_PAGE_SIZE_TODO = "SET_PAGE_NUMBER_AND_PAGE_SIZE_TODO";
export const SET_PAGE_NUMBER_AND_PAGE_SIZE_DELAY =
  "SET_PAGE_NUMBER_AND_PAGE_SIZE_DELAY";

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////projects
//projects
export const ADD_EDIT_COMPANY = "ADD_EDIT_COMPANY";
export const ADD_EDIT_MEMBERSHIPS = "ADD_EDIT_MEMBERSHIPS";
export const ADD_EDIT_VALIDITY = "ADD_EDIT_VALIDITY";
export const ADD_EDIT_PROJECT = "ADD_EDIT_PROJECT";
export const EDIT_NAME_OPTION = "EDIT_NAME_OPTION";

export const DONE_DELETE_DIALOG = "DONE_DELETE_DIALOG";

///////////////////////////////////////////////////////////////////////////////////////////////////////////// Privilege
export const ADD_EDIT_USER = "ADD_EDIT_USER";
export const ADD_EDIT_BRANCH = "ADD_EDIT_BRANCH";
export const ADD_EDIT_ROLE = "ADD_EDIT_ROLE";
//users Privilege
export const ALL_USERS_PRIVILEGE = "ALL_USERS_PRIVILEGE";
export const CHANGE_LOADING_MESSAGE_DIALOG_USER =
  "CHANGE_LOADING_MESSAGE_DIALOG_USER";

export const ADD_USER_PRIVILEGE = "ADD_USER_PRIVILEGE";
export const EDIT_USER_PRIVILEGE = "EDIT_USER_PRIVILEGE";
export const DELETE_USER_PRIVILEGE = "DELETE_USER_PRIVILEGE";

// branchs Privilege
export const ALL_BRANCHS_PRIVILEGE = "ALL_BRANCHS_PRIVILEGE";
export const CHANGE_LOADING_MESSAGE_DIALOG_BRANCH =
  "CHANGE_LOADING_MESSAGE_DIALOG_BRANCH";
export const EDIT_BRANCH_PRIVILEGE = "EDIT_BRANCH_PRIVILEGE";
export const DELETE_BRANCH_PRIVILEGE = "DELETE_BRANCH_PRIVILEGE";
export const CHANGE_ACTIVE_BRANCH_PRIVILEGE = "CHANGE_ACTIVE_BRANCH_PRIVILEGE";
export const ALL_USER_BRANCHS = "ALL_USER_BRANCHS";

// ROLES Privilege
export const DETAILS_ONE_ROLE = "DETAILS_ONE_ROLE";

export const ALL_ROLES_PRIVILEGE = "ALL_ROLES_PRIVILEGE";
export const CHANGE_LOADING_MESSAGE_DIALOG_ROLE =
  "CHANGE_LOADING_MESSAGE_DIALOG_ROLE";
export const EDIT_ROLE_PRIVILEGE = "EDIT_ROLE_PRIVILEGE";
export const DELETE_ROLE_PRIVILEGE = "DELETE_ROLE_PRIVILEGE";

export const ALL_PRIVILEGE = "ALL_PRIVILEGE";

// ROUTES ALLOWED
export const ROUTES_MAIN_PAGES_ALLOWED = "ROUTES_MAIN_PAGES_ALLOWED";
export const ROUTES_PRIVILEGE_ALLOWED = "ROUTES_PRIVILEGE_ALLOWED";
export const SUB_ROUTES_PRIVILEGE_ALLOWED = "SUB_ROUTES_PRIVILEGE_ALLOWED";

export const HOME_MAIN_PAGE = "HOME_MAIN_PAGE";
export const NAME_CURRENT_PAGE = "NAME_CURRENT_PAGE";

////////////////////////////////////////////////////////////////////////////////////////////////////////// setting

//LISTS
export const ADD_EDIT_LISTS = "ADD_EDIT_LISTS";
export const CHANGE_LOADING_MESSAGE_DIALOG_LISTS =
  "CHANGE_LOADING_MESSAGE_DIALOG_LISTS";
// export const LOADING_PAGE_ADD_EDIT_LISTS = "LOADING_PAGE_ADD_EDIT_LISTS";

// country
export const ALL_COUNTRY_LISTS = "ALL_COUNTRY_LISTS";
export const ADD_COUNTRY_LISTS = "ADD_COUNTRY_LISTS";

export const DELETE_COUNTRY_LISTS = "DELETE_COUNTRY_LISTS";
export const EDIT_COUNTRY_LISTS = "EDIT_COUNTRY_LISTS";

export const MAIN_COUNTRY = "MAIN_COUNTRY";
// city
export const ALL_CITY_LISTS = "ALL_CITY_LISTS";
export const DELETE_CITY_LISTS = "DELETE_CITY_LISTS";
export const EDIT_CITY_LISTS = "EDIT_CITY_LISTS";
export const ADD_CITY_LISTS = "ADD_CITY_LISTS";
// area
export const ALL_AREA_LISTS = "ALL_AREA_LISTS";
export const DELETE_AREA_LISTS = "DELETE_AREA_LISTS";
export const EDIT_AREA_LISTS = "EDIT_AREA_LISTS";
export const ADD_AREA_LISTS = "ADD_AREA_LISTS";
//Rooms
export const ALL_ROOMS_LISTS = "ALL_ROOMS_LISTS";
export const DELETE_ROOMS_LISTS = "DELETE_ROOMS_LISTS";
export const EDIT_ROOMS_LISTS = "EDIT_ROOMS_LISTS";
export const ADD_ROOMS_LISTS = "ADD_ROOMS_LISTS";
//Bathroom
export const ALL_BATHROOMS_LISTS = "ALL_BATHROOMS_LISTS";
export const DELETE_BATHROOMS_LISTS = "DELETE_BATHROOMS_LISTS";
export const EDIT_BATHROOMS_LISTS = "EDIT_BATHROOMS_LISTS";
export const ADD_BATHROOMS_LISTS = "ADD_BATHROOMS_LISTS";

//Floor
export const ALL_FLOOR_LISTS = "ALL_FLOOR_LISTS";
export const DELETE_FLOOR_LISTS = "DELETE_FLOOR_LISTS";
export const EDIT_FLOOR_LISTS = "EDIT_FLOOR_LISTS";
export const ADD_FLOOR_LISTS = "ADD_FLOOR_LISTS";

//////FINISHING_TYPE
export const ALL_FINISHING_TYPE_LISTS = "ALL_FINISHING_TYPE_LISTS";
export const DELETE_FINISHING_TYPE_LISTS = "DELETE_FINISHING_TYPE_LISTS";
export const EDIT_FINISHING_TYPE_LISTS = "EDIT_FINISHING_TYPE_LISTS";
export const ADD_FINISHING_TYPE_LISTS = "ADD_FINISHING_TYPE_LISTS";

//////PROPERTY_TYPE
export const ALL_PROPERTY_TYPE_LISTS = "ALL_PROPERTY_TYPE_LISTS";
export const DELETE_PROPERTY_TYPE_LISTS = "DELETE_PROPERTY_TYPE_LISTS";
export const EDIT_PROPERTY_TYPE_LISTS = "EDIT_PROPERTY_TYPE_LISTS";
export const ADD_PROPERTY_TYPE_LISTS = "ADD_PROPERTY_TYPE_LISTS";

//DISTRICT
export const ALL_DISTRICT_LISTS = "ALL_DISTRICT_LISTS";
export const ADD_DISTRICT_LISTS = "ADD_DISTRICT_LISTS";
export const EDIT_DISTRICT_LISTS = "EDIT_DISTRICT_LISTS";
export const DELETE_DISTRICT_LISTS = "DELETE_DISTRICT_LISTS";

//REDION
export const ALL_REGION_LISTS = "ALL_REGION_LISTS";
export const ADD_REGION_LISTS = "ADD_REGION_LISTS";
export const EDIT_REGION_LISTS = "EDIT_REGION_LISTS";
export const DELETE_REGION_LISTS = "DELETE_REGION_LISTS";

// Active Code
export const ACTIVE_CODE = "ACTIVE_CODE";

//LEAD_STATUS
export const ALL_LEAD_STATUS_LISTS = "ALL_LEAD_STATUS_LISTS";
export const ADD_LEAD_STATUS_LISTS = "ADD_LEAD_STATUS_LISTS";
export const EDIT_LEAD_STATUS_LISTS = "EDIT_LEAD_STATUS_LISTS";
export const DELETE_LEAD_STATUS_LISTS = "DELETE_LEAD_STATUS_LISTS";
export const GET_LEAD_DETAILS = "GET_LEAD_DETAILS";
export const LOADING_LEAD_DETAILS = "LOADING_LEAD_DETAILS";

//MARKETING_CHANNEL
export const ALL_MARKETING_CHANNEL_LISTS = "ALL_MARKETING_CHANNEL_LISTS";
export const ADD_MARKETING_CHANNEL_LISTS = "ADD_MARKETING_CHANNEL_LISTS";
export const EDIT_MARKETING_CHANNEL_LISTS = "EDIT_MARKETING_CHANNEL_LISTS";
export const DELETE_MARKETING_CHANNEL_LISTS = "DELETE_MARKETING_CHANNEL_LISTS";

//COMMUNICATION
export const ALL_COMMUNICATION_LISTS = "ALL_COMMUNICATION_LISTS";
export const ADD_COMMUNICATION_LISTS = "ADD_COMMUNICATION_LISTS";
export const EDIT_COMMUNICATION_LISTS = "EDIT_COMMUNICATION_LISTS";
export const DELETE_COMMUNICATION_LISTS = "DELETE_COMMUNICATION_LISTS";

//CANCEL_REASION
export const ALL_CANCEL_REASION_LISTS = "ALL_CANCEL_REASION_LISTS";
export const ADD_CANCEL_REASION_LISTS = "ADD_CANCEL_REASION_LISTS";
export const EDIT_CANCEL_REASION_LISTS = "EDIT_CANCEL_REASION_LISTS";
export const DELETE_CANCEL_REASION_LISTS = "DELETE_CANCEL_REASION_LISTS";

// NEXT_ACTION
export const ALL_NEXT_ACTION_LISTS = "ALL_NEXT_ACTION_LISTS";
export const DELETE_NEXT_ACTION_LISTS = "DELETE_NEXT_ACTION_LISTS";
export const EDIT_NEXT_ACTION_LISTS = "EDIT_NEXT_ACTION_LISTS";
export const ADD_NEXT_ACTION_LISTS = "ADD_NEXT_ACTION_LISTS";

// STATUS
export const ALL_STATUS_LISTS = "ALL_STATUS_LISTS";
export const DELETE_STATUS_LISTS = "DELETE_STATUS_LISTS";
export const EDIT_STATUS_LISTS = "EDIT_STATUS_LISTS";
export const ADD_STATUS_LISTS = "ADD_STATUS_LISTS";

// factory license
export const ALL_FACTORY_LICENSE_LISTS = "ALL_FACTORY_LICENSE_LISTS";
export const DELETE_FACTORY_LICENSE_LISTS = "DELETE_FACTORY_LICENSE_LISTS";
export const EDIT_FACTORY_LICENSE_LISTS = "EDIT_FACTORY_LICENSE_LISTS";
export const ADD_FACTORY_LICENSE_LISTS = "ADD_FACTORY_LICENSE_LISTS";

//Property
export const ALL_PROPERTY_LISTS = "ALL_PROPERTY_LISTS";

///////////////////////////////////////////////////////////////////////////////////////////////////////////////// crm
export const ALL_LEADS = "ALL_LEADS";
export const ADD_LEAD = "ADD_LEAD";
export const EDIT_LEAD = "EDIT_LEAD";
export const DELETE_LEAD = "DELETE_LEAD";

export const EDIT_ACTION_IN_LEAD = "EDIT_ACTION_IN_LEAD";

export const ALL_PROPERTIES_LEAD = "ALL_PROPERTIES_LEAD";

export const SET_LEAD_PAGE_NUMBER_AND_PAGE_SIZE = "SET_LEAD_PAGE_NUMBER_AND_PAGE_SIZE";

// Requests
export const GET_ALL_REQUESTS = "GET_ALL_REQUESTS";
export const ADD_NEW_REQUEST = "ADD_NEW_REQUEST";
export const UPDATE_REQUEST = "UPDATE_REQUEST";


// DASHBOARD CRM
export const ALL_DASHBOARD_CRM = "ALL_DASHBOARD_CRM";
export const ALL_ACTIVITIES = "ALL_ACTIVITIES";
// search request
export const SEARCH_RESULTS = "SEARCH_RESULTS";

/////////////////////////////////////////////////////////////////////////////////////////////////////////// PROJECTS
// COMPANY
export const ALL_COMPANIES = "ALL_COMPANIES";

// Reports
export const GET_ALL_REPORTS_BY_SALES = "GET_ALL_REPORTS_BY_SALES";
export const GET_ALL_REPORTS_BY_MEETINGS = "GET_ALL_REPORTS_BY_MEETINGS";
