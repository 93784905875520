import * as TYPES from "../types";


const initState = {
  allTabs: {loading: false, data: {}},
  formLoading: false,
  properties: {loading: false, data: {}},
  pageNumber: 1,
  pageSize: 10,
  backToAqarFinder: {
    editAqarFinder: false,
    detailsAqarFinder: false,
  }
}

const aqarFinderReducer = (state = initState, action) => {
  switch (action.type) {
    case TYPES.ALL_AQAR_FINDER_TABS:
      return {
        ...state,
        allTabs: action.payload,
      };

    case TYPES.AQAR_FINDER_ADD:
      return {
        ...state,
        formLoading: action.payload.loading,
      };

    case TYPES.GET_AQAR_FINDER_PROPERTIES:
      return {
        ...state,
        properties: action.payload,
      };

    case TYPES.SET_PAGE_NUM_AND_PAGE_SIZE_AQAR_FINDER:
      return {
        ...state,
        pageNumber: action.payload.pageNumber,
        pageSize: action.payload.pageSize,
      };

    case TYPES.EDIT_AQAR_FINDER:
      return {
        ...state,
        backToAqarFinder: action.payload,
      };

    default:
      return state;
  }
}

export default aqarFinderReducer;