/* eslint-disable import/no-anonymous-default-export */
import * as TYPES from "../types";

const deleteById = (allItems, id, nameId = "id") => {
  return {
    loading: false,
    data: allItems.data.filter((list) => list[nameId] !== id),
  };
};

const editById = (allItems, newData, nameOfId = "id") => {
  const findIndexOfList = allItems.data.findIndex(
    (list) => list[nameOfId] === newData[nameOfId]
  );
  const allListToEditOne = allItems;
  allItems.data[findIndexOfList] = newData;

  return [...allListToEditOne.data];
};

const editPropertyById = (allItems, newData) => {
  const findIndexOfList = allItems.data.findIndex(
    (list) => list.proprtyDetailesVM.id === newData.proprtyDetailesVM.id
  );
  const allListToEditOne = allItems;
  allItems.data[findIndexOfList] = newData;

  return [...allListToEditOne.data];
};

const addNew = (allItems, newData) => {
  return [...allItems.data, newData];
};
const editCaseRentOrSold = (detailsProperty, which) => {
  detailsProperty.proprtyDetailesVM[which] =
    !detailsProperty.proprtyDetailesVM[which];
  return { ...detailsProperty };
};

export default (
  state = {
    //owner
    allOwners: { loading: false, data: [] },
    // new owner id
    newOwnerId: null,
    //
    owner: {},
    ownerProperties: {},
    ownersResult: {data: [], loading: false},
    propertyOwner: [],
    //properties
    allProperties: { loading: false, data: [] },
    // add and edit property page
    addAndEditpropertyPage: {},
    //details Property
    detailsProperty: { loadingPage: false, data: {} },
    //details Property UnAuth
    detailsPropertyUnAuth: { loadingPage: false, data: {} },

    //
    exportProperties: {},
    //
    addEditRequest: {},
    // all requests
    allRequest: { loading: false, data: [] },
    //all actions
    allActions: { loading: false, data: [] },
    // all updates
    allUpdates: { loading: false, data: [] },
    updatesPageNumber: 1,
    updatesPageSize: 10,
    // all dashboard
    allDashboard: { loading: false, data: [] },
    //
    allLocationByText: { loading: false, data: [] },
    // page Number
    pageNumber: 1,
    // page size
    pageSize: 10,
    // location to Map
    locationToMap: "",
    // multi property
    multiProperty: [],
  },

  action
) => {
  // console.log(state.allProperties.data)
  switch (action.type) {
    // owners
    case TYPES.ALL_OWNERS:
      return {
        ...state,
        allOwners: action.payload,
      };

    case TYPES.ADD_OWNER:
      return {
        ...state,
        allOwners: {
          loading: false,
          data: addNew(state.allOwners, action.payload),
        },
      };

    case TYPES.GET_OWNERS_BY_NAME_OR_PHONE:
      return {
        ...state,
        ownersResult: action.payload,
      }

    case TYPES.GET_NEW_OWNER_ID:
      return {
        ...state,
        newOwnerId: action.payload,
      };

    case TYPES.GET_OWNER_BY_ID:
      return {
        ...state,
        propertyOwner: action.payload
      }

    case TYPES.DELETE_OWNER:
      return {
        ...state,
        allOwners: deleteById(state.allOwners, action.payload),
      };

    case TYPES.EDIT_OWNER:
      return {
        ...state,
        allOwners: {
          loading: false,
          data: editById(state.allOwners, action.payload),
        },
      };

    case TYPES.SEARCH_BY_PHONE:
      return {
        ...state,
        allOwners: action.payload,
      };

    case TYPES.SEARCH_BY_ADDED_PHONE:
      return {
        ...state,
        owner: action.payload,
      };

    case TYPES.OWNER_PROPERTIES:
      return {
        ...state,
        ownerProperties: action.payload,
      };

    // get locations to  filter property by text

    case TYPES.ALL_LOCATION_BY_TEXT:
      return {
        ...state,
        allLocationByText: action.payload,
      };
    // properties

    case TYPES.ALL_PROPERTIES:
      return {
        ...state,
        allProperties: action.payload,
      };

    case TYPES.ADD_PROPERTY:
      console.log(state.allProperties);
      let newProperty = action.payload;
      let allProperties = [];
      allProperties.push(newProperty);
      return {
        ...state,
        allProperties: {
          loading: false,
          data: allProperties,
          // data: addNew(state.allProperties, action.payload),
        },
      };

    case TYPES.DELETE_PROPERTY:
      return {
        ...state,
        allProperties: {
          loading: false,
          data: state.allProperties.data.data.filter(
            (property) => property.proprtyDetailesVM.id !== action.payload
          ),
        },

        // deleteById(state.allProperties, action.payload),
      };

    case TYPES.EDIT_PROPERTY:
      return {
        ...state,
        allProperties: {
          loading: false,
          data: editPropertyById(state.allProperties, action.payload),
        },
      };

    case TYPES.ADD_EDIT_PROPERTY_PAGE:
      return {
        ...state,
        addAndEditpropertyPage: action.payload,
      };

    case TYPES.CHANGE_LOADING_MESSAGE_ADD_EDIT_PROPERTY_PAGE:
      return {
        ...state,
        addAndEditpropertyPage: {
          ...state.addAndEditpropertyPage,
          ...action.payload,
        },
      };

    case TYPES.SET_PAGE_NUMBER_AND_PAGE_SIZE:
      return {
        ...state,
        pageNumber: action.payload.pageNumber,
        pageSize: action.payload.pageSize,
      };

    // details prpperty
    case TYPES.DETAILS_PROPERTY:
      return {
        ...state,
        detailsProperty: action.payload,
      };

    case TYPES.GET_DETAILS_PROPERTY_UNAUTH:
      return {
        ...state,
        detailsPropertyUnAuth: action.payload,
      };

    case TYPES.GET_MULTI_PROPERTY_UNAUTH:
      return {
        ...state,
        multiProperty: action.payload,
      };

    case TYPES.CHANGE_CASE_RENT_OR_SOLD:
      return {
        ...state,

        detailsProperty: {
          loadingPage: false,
          data: editCaseRentOrSold(state.detailsProperty.data, action.payload),
        },
      };

    case TYPES.CHANGE_STATUS_IN_DETAILS:
      return {
        ...state,

        detailsProperty: {
          loadingPage: false,
          data: action.payload,
        },
      };

    //
    case TYPES.EXPORT_PROPERTIES:
      return {
        ...state,
        exportProperties: action.payload,
      };

    // dialog Request

    case TYPES.ADD_EDIT_REQUEST:
      return {
        ...state,
        addEditRequest: action.payload,
      };

    case TYPES.CHANGE_LOADING_MESSAGE_DIALOG_REQUEST:
      return {
        ...state,
        addEditRequest: { ...state.addEditRequest, ...action.payload },
      };

    // Request
    case TYPES.ALL_REQUEST:
      return {
        ...state,
        allRequest: action.payload,
      };

    case TYPES.ALL_ACTIONS:
      return {
        ...state,
        allActions: action.payload,
      };

    // Request
    case TYPES.EDIT_REQUEST:
      return {
        ...state,
        allRequest: {
          loading: false,
          data: editById(state.allRequest, action.payload),
        },
      };

    // updates
    case TYPES.ALL_UPDATES:
      return {
        ...state,
        allUpdates: action.payload,
      };

    case TYPES.SET_PAGE_NUM_AND_PAGE_SIZE_UPDATES:
      return{
        ...state,
        updatesPageNumber: action.payload.pageNumber,
        updatesPageSize: action.payload.pageSize,
      }

    case TYPES.DELETE_UPDATE:
      return {
        ...state,
        allUpdates: {
          loading: false,
          data: state.allUpdates.data.filter(
            (property) => property.proprtyDetailesVM.id !== action.payload
          ),
        },
      };

    case TYPES.EDIT_UPDATE:
      return {
        ...state,
        allUpdates: {
          loading: false,
          data: editPropertyById(state.allUpdates, action.payload),
        },
      };

    // dashboard
    case TYPES.ALL_DASHBOARD:
      return {
        ...state,
        allDashboard: action.payload,
      };

    case TYPES.GET_LOCATION_TO_MAP:
      return {
        ...state,
        locationToMap: action.payload,
      };

    default:
      return state;
  }
};
