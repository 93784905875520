import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const CarouselWithDots = ({ srcImages, dots }) => {
  const CustomDot = ({ onClick, ...rest }) => {
    const {
      //onMove,
      index,
      active,
      //carouselState: { currentSlide, deviceType },
    } = rest;
    const carouselItems = srcImages;
    // onMove means if dragging or swiping in progress.
    // active is provided by this lib for checking if the item is active or not.
    return (
      <img
        className={active ? "active" : "inactive"}
        onClick={() => onClick()}
        src={React.Children.toArray(carouselItems)[index]}
        alt={index}
      />
    );
  };


  return (
    <div className="carousel">
      <Carousel
        additionalTransfrom={0}
        arrows
        autoPlaySpeed={3000}
        centerMode={false}
        className=""
        containerClass="container"
        customDot={dots && <CustomDot />} /////
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite
        itemClass=""
        keyBoardControl
        minimumTouchDrag={80}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024,
            },
            items: 1,
          },
          mobile: {
            breakpoint: {
              max: 464,
              min: 0,
            },
            items: 1,
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 464,
            },
            items: 1,
          },
        }}
        showDots={dots} //////
        sliderClass=""
        slidesToSlide={1}
        swipeable
      >
        {srcImages.map((srcImage, index) => (
          <img
            src={srcImage}
            className="scrImage"
            width="100%"
            height="100%"
            alt={index}
            key={index}
          />
        ))}
      </Carousel>
    </div>
  );
};

export default CarouselWithDots;
