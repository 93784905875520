import * as TYPES from "../../types";

const initState = {
  todoList: { loading: false, data: []},
  delayList: { loading: false, data: []},
  todoPagination: {pageNumber: 1, pageSize: 10},
  delayPagination: {pageNumber: 1, pageSize: 10}
}

const todoAndDelayReducer = (state = initState, action) => {
  switch (action.type) {
    case TYPES.GET_TODO_LIST:
      return {
        ...state,
        todoList: action.payload
      }
      
    case TYPES.GET_DELAY_LIST:
      return {
        ...state,
        delayList: action.payload
      }

    case TYPES.SET_PAGE_NUMBER_AND_PAGE_SIZE_TODO:
      return {
        ...state,
        todoPagination: action.payload
      }

    case TYPES.SET_PAGE_NUMBER_AND_PAGE_SIZE_DELAY:
      return {
        ...state,
        delayPagination: action.payload
      }

    default:
      return state;
  }
}

export default todoAndDelayReducer;