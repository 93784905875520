/* eslint-disable import/no-anonymous-default-export */
import * as TYPES from "../../types";

const deleteLeadById = (allItems, id) => {
  return {
    loading: false,
    data: allItems.data.filter((lead) => lead?.leadDetailesVM?.id !== id),
  };
};

const editLeadById = (allItems, newData) => {
  const findIndexOfList = allItems.data.findIndex(
    (lead) => lead?.leadDetailesVM?.id === newData?.leadDetailesVM?.id
  );
  const allListToEditOne = allItems;
  allItems.data[findIndexOfList] = newData;

  return [...allListToEditOne.data];
};

const addNew = (allItems, newData) => {
  return [...allItems.data, newData];
};

export default (
  state = {
    allCompanies: { data: [], loading: false },
  },

  action
) => {
  switch (action.type) {
    case TYPES.ALL_COMPANIES:
      return {
        ...state,
        allCompanies: action.payload,
      };

    case TYPES.ADD_LEAD:
      return {
        ...state,
        allLeads: {
          loading: false,
          data: addNew(state.allLeads, action.payload),
        },
      };

    case TYPES.DELETE_LEAD:
      return {
        ...state,
        allLeads: deleteLeadById(state.allLeads, action.payload),
      };

    case TYPES.EDIT_LEAD:
      return {
        ...state,
        allLeads: {
          loading: false,
          data: editLeadById(state.allLeads, action.payload),
        },
      };

    default:
      return state;
  }
};
